.dropdown {
  position: relative;
  display: inline-block;

  &__label {
    color: $color-dark-blue;
    font-family: $primary-font;
    font-size: 1.375rem;
    font-weight: 700;
    font-style: normal;
    text-align: right;
    margin-right: 20px;
  }
  &__init {
    font-size: 1.125rem;
    display: inline-block;
    width: 144px;
    background-color: $color-red;
    text-align: left;
    font-style: italic;
    color: $color-white;
    padding: 20px;
    &:after {
      content: url("/static/core/img/arrow-down.png");
      width: 18px;
      height: 28px;
      right: 20px;
      text-align: center;
      position: absolute;
    }
    &:hover {
      cursor: pointer;
    }
    &:hover .dropdown__content {
      display: block;
    }
  }

  &__content {
    display: none;
    position: absolute;
    z-index: 1;
    list-style: none;
    width: 100%;
    padding: 0;
  }
  &item {}
  &__link {
    display: inline-block;
    width: 100%;
    padding: 20px;
    background-color: $color-white;
    color: $color-grey2;
    font-family: $primary-font;
    font-size: 1.125rem;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    text-align: left;

    &:hover {
      background: $color-grey;
      text-decoration: none;
    }
  }
}
.dropdown:hover .dropdown__content {
  display: block;
}

@media (min-width: $large) {
  .dropdown {
    &__init {
      width: 236px;
    }
  }
}