.smart-popin {
    position: fixed;
    left: 0; right: 0;
    top: 0; bottom: 0;
    overflow: auto;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0,0,0,0.5);
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 9999;
    &:target {
        opacity: 1;
        visibility: visible;
    }
    .sp-table {
        display: table;
        height: 100%;
        width: 100%;
    }
     .sp-cell {
        display: table-cell;
        vertical-align: middle;
        padding: 10px;
    }
    .sp-body {
        position: relative;
        z-index: 1;
        width: auto;
        max-width: 900px;
        margin: 90px auto;
    }
    .sp-body * {
        max-width: 100%;
    }
    .sp-back { /* only used to get clicks out of the popin body */
        position: fixed;
        left: 0; right: 0;
        top: 0; bottom: 0;
        display: block;
    }

    .sp-close {
        position: absolute;
        top: -70px;
        right: 0;

        width: 56px;
        height: 56px;

        text-align: center;
        line-height: 56px;
        font-size: 3rem;
        //color: #2b2b2b;
        background-color: $color-white;
        font-weight: 900;
        text-decoration: none;
        border-radius: 28px;
    }
}

.urgenceNumber {
    padding: 2.5rem .75rem 1.875rem 1.45rem;
    &__title{
        font-size: 4.5rem;
        color: $color-white;
        font-weight: 600;
        font-family: $primary-font;
        text-transform: uppercase;
        line-height: 4rem;
        margin-bottom: .375rem;
        em {
            color: $color-white;
            margin-left: 2.625rem;
            font-size: 6.25rem;
            font-family: $font2-rounded;
            font-style: normal;
            font-weight: 700;
        }
    }
    &__subTitle{
        color: $color-white;
        font-family: $primary-font;
        font-size: 1.25rem;
        font-weight: 600;
        text-transform: uppercase;
    }
    &__text {
        color: $color-white;
        font-family: $primary-font;
        font-size: 1.20rem;
        font-weight: 400;
        line-height: normal;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &:nth-child(1) {background-color: #e8303a;
        .urgenceNumber__text, .urgenceNumber__subTitle {
            color: $color-white;
        }
    }
    &:nth-child(2) {
        background-color: #2c73ab;
        .urgenceNumber__text, .urgenceNumber__subTitle {
            //color: #7ddaff;
        }
    }
    &:nth-child(3) {
        background-color: #2b2b2b;
        .urgenceNumber__text,
        .urgenceNumber__title, .urgenceNumber__title em,
        .urgenceNumber__subTitle {
            //color: #d9d9d9;
        }
        .urgenceNumber__title {
            font-size: 3.75rem;
            line-height: 3.1rem;
            & em {
                padding-bottom: 1.5rem;
                display: inline-block;
            }
        }
    }
    &:nth-child(4) {
        background-color: #ffa32a;
        .urgenceNumber__text,
        .urgenceNumber__title, .urgenceNumber__title em,
        .urgenceNumber__subTitle {
            //color: $color-white;
        }
        .urgenceNumber__subTitle {
            font-size: 1.875rem;
            line-height: 1.75rem;

        }
        .urgenceNumber__text {
            font-size: 1rem;
        }
    }
    &:nth-child(5) {
        background-color: #2cbbf3;
        .urgenceNumber__text,
        .urgenceNumber__subTitle {
            //color: #a434ff;
        }
        .urgenceNumber__subTitle {
            font-size: 1.875rem;
            line-height: 1.75rem;
        }
    }

    &:nth-child(6) {
        background-color: #745a9b;
        .urgenceNumber__text,
        .urgenceNumber__subTitle {
            //color: #ff4a70;
        }
        .urgenceNumber__subTitle {
            font-size: 1.875rem;
            line-height: 1.75rem;
        }
    }
    &:nth-child(7) {
        background-color: #f14249;
        .urgenceNumber__text,
        .urgenceNumber__subTitle,
        .urgenceNumber__subTitle strong{
            //color: #ffcc00;
        }
        .urgenceNumber__subTitle {
            font-size: 3rem;
            line-height: 1.75rem;
            strong {
                font-size: 4.5rem;
                line-height: 1.75rem;
                color: $color-white;
            }
        }
    }
    &:nth-child(8) {
        background-color: #304b5e;
        .urgenceNumber__title {
            //color: $color-white;
            font-size: 2.75rem;
            line-height: 2.70rem;
        }
        .urgenceNumber__subTitle {
            //color: #ff4a70;
            font-size: 2.31rem;
            line-height: 2.30rem;
        }
        .urgenceNumber__text {
            margin-top: 20px;
            margin-left: 1.875rem;
            padding-right: 1rem;
            font-size: .90rem;
            //color: #7ddaff;
            text-transform: uppercase;
            strong {
                color: $color-white;
                font-size: .90rem;
                letter-spacing: normal;
            }
        }
    }
}
