input[type=text], input[type=search] {
  background-color: $color-white;
  height: 40px;
  margin: 12px 10px 12px 0;
  font-family: $primary-font;
  font-size: 1rem;
  font-weight: 400;
  font-style: italic;
  color: $color-grey1;
  padding-left: 20px;
  max-width: 285px;
  //width: 100%;
  //max-width: 240px;

  &.input--longWidth {
    max-width: 355px;
  }
}

input[type=search]:hover {
  cursor: text;
}

textarea {
  margin-top: 12px;
  background-color: $color-white;
  width: 100%;
  height: 350px;
  padding: 10px 20px;
}
textarea::placeholder, input::placeholder {
  font-family: $primary-font;
  font-size: 1rem;
  font-weight: 400;
  font-style: italic;
  color: $color-grey1;
}


@media (max-width: $medium) {
  input[type=text], input[type=search] {
    &.input--longWidth {
      max-width: none;
      width: 100%;
    }
  }
}
@media (min-width: $medium) {
  input[type=text], input[type=search] {
    width: 285px;
  }
}