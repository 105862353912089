@charset "UTF-8";
/*!
* CH Cognac
* @author: ssorin - <ssorin@dwdlc.com>
* v1.0 2022/06
*/
/*!
* KNACSS Reborn: Just keep it simple!
* @author: Alsacreations
* v8.2 2022/01
* Licence WTFPL http://www.wtfpl.net/
*/
/* ----------------------------- */
/* ==Reset (base)                */
/* ----------------------------- */
/*
 * 1. Switch to border-box model for all elements
 * 2. Avoid min-width: auto on flex and grid items
 */
@import url("https://p.typekit.net/p.css?s=1&k=skm4jft&ht=tk&f=8448.8449.8454.8455.14429.14430.14435.14436&a=4262462&app=typekit&e=css");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box;
  /* 1 */
  min-width: 0;
  /* 2 */
}

/*
 * 1. Remove the grey highlight on links in iOS 
 * 2. Prevent orientation font changes in iOS
 * 3. Breaks words to prevent overflow in all browsers
 */
html {
  font-size: 100%;
  -webkit-tap-highlight-color: transparent;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  overflow-wrap: break-word;
  /* 3 */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  line-height: 1.5;
  background-color: #FFFFFF;
  color: #212529;
}

/*
 * Headings
 */
h1,
.h1-like,
h2,
.h2-like,
h3,
.h3-like,
h4,
.h4-like,
h5,
.h5-like,
h6,
.h6-like {
  color: #212529;
}

/*
 * Links
 */
a {
  color: #6c757d;
  text-decoration: underline;
}

a:focus,
a:hover,
a:active {
  color: #212529;
  text-decoration: underline;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/*
 * Vertical rythm
 */
h1,
.h1-like,
h2,
.h2-like {
  margin-top: 0;
  margin-bottom: 1rem;
}

p,
address,
ol,
ul,
dl,
blockquote,
pre,
h3,
.h3-like,
h4,
.h4-like,
h5,
.h5-like,
h6,
.h6-like,
dt,
dd {
  margin-top: 0;
  margin-bottom: 0.75rem;
}

/*
 * Nested elements
 */
ol ol,
ol ul,
ul ol,
ul ul,
li ul,
li ol,
nav ul,
nav ol,
li p,
li .p-like {
  margin-top: 0;
  margin-bottom: 0;
}

/*
 * Lists
 */
ul,
ol {
  padding-left: 1rem;
}

nav ul,
nav ol {
  list-style: none;
  padding: 0;
}

/*
 * Embed content
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

img,
table,
td,
blockquote,
pre,
code,
input,
textarea,
select,
video,
svg,
iframe {
  max-width: 100%;
}

img,
input,
select,
textarea {
  height: auto;
}

img {
  border-style: none;
}

/**
 * Remove the border on iframes in all browsers
 */
iframe {
  border-style: none;
}

/*
 * Fill color matching to text color
 */
svg:not([fill]) {
  fill: currentColor;
}

/*
 * Hide the overflow in IE
 */
svg:not(:root) {
  overflow: hidden;
}

/*
 * Tables
 */
table {
  border-collapse: collapse;
}

/*
 * Rulers
 */
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
  clear: both;
  color: inherit;
}

/*
 * table styles
 */
table {
  width: 100%;
  max-width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  vertical-align: top;
  margin-bottom: 1rem;
}

/* 
 * Hidden but not for an assistive technology like a screen reader, Yahoo! method 
 */
.visually-hidden,
.sr-only {
  position: absolute !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
}

/*
 * Disable animations styles when reduced motion is enabled
 */
@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}
/*
 * Change the cursor on busy elements in all browsers.
 */
[aria-busy=true] {
  cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers.
 */
[aria-controls] {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers.
 */
[aria-disabled=true],
[disabled] {
  cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers.
 */
[aria-hidden=false][hidden] {
  display: initial;
}

[aria-hidden=false][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

/* ----------------------------- */
/* ==Reset (forms)               */
/* ----------------------------- */
/*
 * Remove the tapping delay on clickable elements in all browsers .
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

/*
 * 1. Change the inconsistent appearance in all browsers.
 * 2. Add typography inheritance in all browsers.
 */
button,
input,
select,
textarea {
  margin: 0;
  background-color: transparent; /* 1 */
  color: inherit; /* 1 */
  font-family: inherit; /* 2 */
  font-size: inherit; /* 2 */
  line-height: inherit; /* 2 */
  letter-spacing: inherit; /* 2 */
  vertical-align: middle;
}

/*
 * Basic User Interface reset
 */
button,
input:not([type=radio]):not([type=checkbox]),
select,
textarea {
  border: 0;
}

/*
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre,
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

pre {
  tab-size: 2;
  white-space: pre-wrap;
  line-height: normal;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

/*
 * Show overflow in IE/Edge
 */
button,
input {
  overflow: visible;
}

/* 
 * Remove the inheritance of text transform in Firefox
 */
button,
select {
  text-transform: none;
}

/*
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

form,
fieldset {
  border: none;
}

fieldset {
  margin: 0;
  padding: 1rem;
}

legend {
  display: table;
  max-width: 100%;
  padding: 0 0.25rem;
  border: 0;
  color: inherit;
  white-space: normal;
}

label {
  display: inline-block;
  cursor: pointer;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
  white-space: pre-wrap;
}

progress {
  display: inline-block;
  width: 100%;
  vertical-align: baseline;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

/* ----------------------------- */
/* Form oddities                 */
/* ----------------------------- */
/*
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/*
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/*
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/*
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/*
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/*
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/*
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}

/* ----------------------------- */
/* ==Print (quick print reset)   */
/* ----------------------------- */
@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  body {
    width: auto;
    margin: auto;
    font-family: serif;
    font-size: 12pt;
  }
  p,
.p-like,
h1,
.h1-like,
h2,
.h2-like,
h3,
.h3-like,
h4,
.h4-like,
h5,
.h5-like,
h6,
.h6-like,
blockquote,
label,
ul,
ol {
    color: #000;
    margin: auto;
  }
  .print {
    display: block;
  }
  .no-print {
    display: none;
  }
  /* no orphans, no widows */
  p,
.p-like,
blockquote {
    orphans: 3;
    widows: 3;
  }
  /* no breaks inside these elements */
  blockquote,
ul,
ol {
    page-break-inside: avoid;
  }
  /* page break before main headers
  h1,
  .h1-like {
    page-break-before: always;
  }
  */
  /* no breaks after these elements */
  h1,
.h1-like,
h2,
.h2-like,
h3,
.h3-like,
caption {
    page-break-after: avoid;
  }
  a {
    color: #000;
  }
  /* displaying URLs
  a[href]::after {
    content: " (" attr(href) ")";
  }
  */
  a[href^="javascript:"]::after,
a[href^="#"]::after {
    content: "";
  }
}
/* ----------------------------  */
/* ==Layout classes              */
/* ----------------------------- */
/* Global container */
.layout-maxed {
  display: grid;
}
@media (min-width: 576px) {
  .layout-maxed {
    grid-template-columns: minmax(0.75rem, 1fr) minmax(auto, 576px) minmax(0.75rem, 1fr);
  }
}
@media (min-width: 992px) {
  .layout-maxed {
    grid-template-columns: minmax(0.75rem, 1fr) minmax(auto, 992px) minmax(0.75rem, 1fr);
  }
}
@media (min-width: 1200px) {
  .layout-maxed {
    grid-template-columns: minmax(0.75rem, 1fr) minmax(auto, 1200px) minmax(0.75rem, 1fr);
  }
}

/* Center all children */
.layout-maxed > * {
  grid-column: 2;
}

/* Hero box  */
.layout-hero {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: inherit;
}

.layout-hero > * {
  grid-column: 2;
}

/* Hero image */
.layout-hero-img {
  grid-column: 1/-1;
  justify-self: center;
  max-width: 100%;
}

/* 
 * Global utility classes 
 */
.hidden {
  display: none;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-no-grow {
  flex-grow: 0;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-smaller {
  font-size: smaller;
}

.text-larger {
  font-size: larger;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-wrap {
  overflow-wrap: break-word;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-end {
  justify-items: end;
}

.justify-items-center {
  justify-items: center;
}

.align-start {
  align-content: start;
}

.align-end {
  align-content: end;
}

.align-center {
  align-content: center;
}

.align-between {
  align-content: space-between;
}

.align-around {
  align-content: space-around;
}

.align-evenly {
  align-content: space-evenly;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.place-center {
  place-content: center;
}

.justify-self-auto {
  justify-self: auto;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-stretch {
  justify-self: stretch;
}

.align-self-auto {
  align-self: auto;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-stretch {
  align-self: stretch;
}

.align-top {
  vertical-align: top;
}

.align-bottom {
  vertical-align: bottom;
}

.align-middle {
  vertical-align: middle;
}

.item-first {
  order: -100;
}

.item-last {
  order: 100;
}

@media (min-width: 576px) {
  .sm\:hidden {
    display: none;
  }
}
@media (min-width: 992px) {
  .md\:hidden {
    display: none;
  }
}
@media (min-width: 1200px) {
  .lg\:hidden {
    display: none;
  }
}
@media (min-width: 576px) {
  .sm\:block {
    display: block;
  }
}
@media (min-width: 992px) {
  .md\:block {
    display: block;
  }
}
@media (min-width: 1200px) {
  .lg\:block {
    display: block;
  }
}
@media (min-width: 576px) {
  .sm\:inline {
    display: inline;
  }
}
@media (min-width: 992px) {
  .md\:inline {
    display: inline;
  }
}
@media (min-width: 1200px) {
  .lg\:inline {
    display: inline;
  }
}
@media (min-width: 576px) {
  .sm\:inline-block {
    display: inline-block;
  }
}
@media (min-width: 992px) {
  .md\:inline-block {
    display: inline-block;
  }
}
@media (min-width: 1200px) {
  .lg\:inline-block {
    display: inline-block;
  }
}
@media (min-width: 576px) {
  .sm\:flex {
    display: flex;
  }
}
@media (min-width: 992px) {
  .md\:flex {
    display: flex;
  }
}
@media (min-width: 1200px) {
  .lg\:flex {
    display: flex;
  }
}
@media (min-width: 576px) {
  .sm\:flex-row {
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  .md\:flex-row {
    flex-direction: row;
  }
}
@media (min-width: 1200px) {
  .lg\:flex-row {
    flex-direction: row;
  }
}
@media (min-width: 576px) {
  .sm\:flex-col {
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  .md\:flex-col {
    flex-direction: column;
  }
}
@media (min-width: 1200px) {
  .lg\:flex-col {
    flex-direction: column;
  }
}
@media (min-width: 576px) {
  .sm\:flex-wrap {
    flex-wrap: wrap;
  }
}
@media (min-width: 992px) {
  .md\:flex-wrap {
    flex-wrap: wrap;
  }
}
@media (min-width: 1200px) {
  .lg\:flex-wrap {
    flex-wrap: wrap;
  }
}
@media (min-width: 576px) {
  .sm\:flex-no-wrap {
    flex-wrap: nowrap;
  }
}
@media (min-width: 992px) {
  .md\:flex-no-wrap {
    flex-wrap: nowrap;
  }
}
@media (min-width: 1200px) {
  .lg\:flex-no-wrap {
    flex-wrap: nowrap;
  }
}
@media (min-width: 576px) {
  .sm\:flex-shrink {
    flex-shrink: 1;
  }
}
@media (min-width: 992px) {
  .md\:flex-shrink {
    flex-shrink: 1;
  }
}
@media (min-width: 1200px) {
  .lg\:flex-shrink {
    flex-shrink: 1;
  }
}
@media (min-width: 576px) {
  .sm\:flex-no-shrink {
    flex-shrink: 0;
  }
}
@media (min-width: 992px) {
  .md\:flex-no-shrink {
    flex-shrink: 0;
  }
}
@media (min-width: 1200px) {
  .lg\:flex-no-shrink {
    flex-shrink: 0;
  }
}
@media (min-width: 576px) {
  .sm\:flex-grow {
    flex-grow: 1;
  }
}
@media (min-width: 992px) {
  .md\:flex-grow {
    flex-grow: 1;
  }
}
@media (min-width: 1200px) {
  .lg\:flex-grow {
    flex-grow: 1;
  }
}
@media (min-width: 576px) {
  .sm\:flex-no-grow {
    flex-grow: 0;
  }
}
@media (min-width: 992px) {
  .md\:flex-no-grow {
    flex-grow: 0;
  }
}
@media (min-width: 1200px) {
  .lg\:flex-no-grow {
    flex-grow: 0;
  }
}
@media (min-width: 576px) {
  .sm\:float-left {
    float: left;
  }
}
@media (min-width: 992px) {
  .md\:float-left {
    float: left;
  }
}
@media (min-width: 1200px) {
  .lg\:float-left {
    float: left;
  }
}
@media (min-width: 576px) {
  .sm\:float-right {
    float: right;
  }
}
@media (min-width: 992px) {
  .md\:float-right {
    float: right;
  }
}
@media (min-width: 1200px) {
  .lg\:float-right {
    float: right;
  }
}
@media (min-width: 576px) {
  .sm\:float-none {
    float: none;
  }
}
@media (min-width: 992px) {
  .md\:float-none {
    float: none;
  }
}
@media (min-width: 1200px) {
  .lg\:float-none {
    float: none;
  }
}
@media (min-width: 576px) {
  .sm\:text-bold {
    font-weight: bold;
  }
}
@media (min-width: 992px) {
  .md\:text-bold {
    font-weight: bold;
  }
}
@media (min-width: 1200px) {
  .lg\:text-bold {
    font-weight: bold;
  }
}
@media (min-width: 576px) {
  .sm\:text-italic {
    font-style: italic;
  }
}
@media (min-width: 992px) {
  .md\:text-italic {
    font-style: italic;
  }
}
@media (min-width: 1200px) {
  .lg\:text-italic {
    font-style: italic;
  }
}
@media (min-width: 576px) {
  .sm\:text-uppercase {
    text-transform: uppercase;
  }
}
@media (min-width: 992px) {
  .md\:text-uppercase {
    text-transform: uppercase;
  }
}
@media (min-width: 1200px) {
  .lg\:text-uppercase {
    text-transform: uppercase;
  }
}
@media (min-width: 576px) {
  .sm\:text-lowercase {
    text-transform: lowercase;
  }
}
@media (min-width: 992px) {
  .md\:text-lowercase {
    text-transform: lowercase;
  }
}
@media (min-width: 1200px) {
  .lg\:text-lowercase {
    text-transform: lowercase;
  }
}
@media (min-width: 576px) {
  .sm\:text-smaller {
    font-size: smaller;
  }
}
@media (min-width: 992px) {
  .md\:text-smaller {
    font-size: smaller;
  }
}
@media (min-width: 1200px) {
  .lg\:text-smaller {
    font-size: smaller;
  }
}
@media (min-width: 576px) {
  .sm\:text-larger {
    font-size: larger;
  }
}
@media (min-width: 992px) {
  .md\:text-larger {
    font-size: larger;
  }
}
@media (min-width: 1200px) {
  .lg\:text-larger {
    font-size: larger;
  }
}
@media (min-width: 576px) {
  .sm\:text-left {
    text-align: left;
  }
}
@media (min-width: 992px) {
  .md\:text-left {
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .lg\:text-left {
    text-align: left;
  }
}
@media (min-width: 576px) {
  .sm\:text-center {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .md\:text-center {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .lg\:text-center {
    text-align: center;
  }
}
@media (min-width: 576px) {
  .sm\:text-right {
    text-align: right;
  }
}
@media (min-width: 992px) {
  .md\:text-right {
    text-align: right;
  }
}
@media (min-width: 1200px) {
  .lg\:text-right {
    text-align: right;
  }
}
@media (min-width: 576px) {
  .sm\:text-justify {
    text-align: justify;
  }
}
@media (min-width: 992px) {
  .md\:text-justify {
    text-align: justify;
  }
}
@media (min-width: 1200px) {
  .lg\:text-justify {
    text-align: justify;
  }
}
@media (min-width: 576px) {
  .sm\:text-wrap {
    overflow-wrap: break-word;
  }
}
@media (min-width: 992px) {
  .md\:text-wrap {
    overflow-wrap: break-word;
  }
}
@media (min-width: 1200px) {
  .lg\:text-wrap {
    overflow-wrap: break-word;
  }
}
@media (min-width: 576px) {
  .sm\:justify-start {
    justify-content: flex-start;
  }
}
@media (min-width: 992px) {
  .md\:justify-start {
    justify-content: flex-start;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-start {
    justify-content: flex-start;
  }
}
@media (min-width: 576px) {
  .sm\:justify-end {
    justify-content: flex-end;
  }
}
@media (min-width: 992px) {
  .md\:justify-end {
    justify-content: flex-end;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-end {
    justify-content: flex-end;
  }
}
@media (min-width: 576px) {
  .sm\:justify-center {
    justify-content: center;
  }
}
@media (min-width: 992px) {
  .md\:justify-center {
    justify-content: center;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-center {
    justify-content: center;
  }
}
@media (min-width: 576px) {
  .sm\:justify-between {
    justify-content: space-between;
  }
}
@media (min-width: 992px) {
  .md\:justify-between {
    justify-content: space-between;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-between {
    justify-content: space-between;
  }
}
@media (min-width: 576px) {
  .sm\:justify-around {
    justify-content: space-around;
  }
}
@media (min-width: 992px) {
  .md\:justify-around {
    justify-content: space-around;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-around {
    justify-content: space-around;
  }
}
@media (min-width: 576px) {
  .sm\:justify-evenly {
    justify-content: space-evenly;
  }
}
@media (min-width: 992px) {
  .md\:justify-evenly {
    justify-content: space-evenly;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-evenly {
    justify-content: space-evenly;
  }
}
@media (min-width: 576px) {
  .sm\:justify-items-start {
    justify-items: start;
  }
}
@media (min-width: 992px) {
  .md\:justify-items-start {
    justify-items: start;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-items-start {
    justify-items: start;
  }
}
@media (min-width: 576px) {
  .sm\:justify-items-end {
    justify-items: end;
  }
}
@media (min-width: 992px) {
  .md\:justify-items-end {
    justify-items: end;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-items-end {
    justify-items: end;
  }
}
@media (min-width: 576px) {
  .sm\:justify-items-center {
    justify-items: center;
  }
}
@media (min-width: 992px) {
  .md\:justify-items-center {
    justify-items: center;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-items-center {
    justify-items: center;
  }
}
@media (min-width: 576px) {
  .sm\:align-start {
    align-content: start;
  }
}
@media (min-width: 992px) {
  .md\:align-start {
    align-content: start;
  }
}
@media (min-width: 1200px) {
  .lg\:align-start {
    align-content: start;
  }
}
@media (min-width: 576px) {
  .sm\:align-end {
    align-content: end;
  }
}
@media (min-width: 992px) {
  .md\:align-end {
    align-content: end;
  }
}
@media (min-width: 1200px) {
  .lg\:align-end {
    align-content: end;
  }
}
@media (min-width: 576px) {
  .sm\:align-center {
    align-content: center;
  }
}
@media (min-width: 992px) {
  .md\:align-center {
    align-content: center;
  }
}
@media (min-width: 1200px) {
  .lg\:align-center {
    align-content: center;
  }
}
@media (min-width: 576px) {
  .sm\:align-between {
    align-content: space-between;
  }
}
@media (min-width: 992px) {
  .md\:align-between {
    align-content: space-between;
  }
}
@media (min-width: 1200px) {
  .lg\:align-between {
    align-content: space-between;
  }
}
@media (min-width: 576px) {
  .sm\:align-around {
    align-content: space-around;
  }
}
@media (min-width: 992px) {
  .md\:align-around {
    align-content: space-around;
  }
}
@media (min-width: 1200px) {
  .lg\:align-around {
    align-content: space-around;
  }
}
@media (min-width: 576px) {
  .sm\:align-evenly {
    align-content: space-evenly;
  }
}
@media (min-width: 992px) {
  .md\:align-evenly {
    align-content: space-evenly;
  }
}
@media (min-width: 1200px) {
  .lg\:align-evenly {
    align-content: space-evenly;
  }
}
@media (min-width: 576px) {
  .sm\:align-items-start {
    align-items: flex-start;
  }
}
@media (min-width: 992px) {
  .md\:align-items-start {
    align-items: flex-start;
  }
}
@media (min-width: 1200px) {
  .lg\:align-items-start {
    align-items: flex-start;
  }
}
@media (min-width: 576px) {
  .sm\:align-items-end {
    align-items: flex-end;
  }
}
@media (min-width: 992px) {
  .md\:align-items-end {
    align-items: flex-end;
  }
}
@media (min-width: 1200px) {
  .lg\:align-items-end {
    align-items: flex-end;
  }
}
@media (min-width: 576px) {
  .sm\:align-items-center {
    align-items: center;
  }
}
@media (min-width: 992px) {
  .md\:align-items-center {
    align-items: center;
  }
}
@media (min-width: 1200px) {
  .lg\:align-items-center {
    align-items: center;
  }
}
@media (min-width: 576px) {
  .sm\:place-center {
    place-content: center;
  }
}
@media (min-width: 992px) {
  .md\:place-center {
    place-content: center;
  }
}
@media (min-width: 1200px) {
  .lg\:place-center {
    place-content: center;
  }
}
@media (min-width: 576px) {
  .sm\:justify-self-auto {
    justify-self: auto;
  }
}
@media (min-width: 992px) {
  .md\:justify-self-auto {
    justify-self: auto;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-self-auto {
    justify-self: auto;
  }
}
@media (min-width: 576px) {
  .sm\:justify-self-start {
    justify-self: start;
  }
}
@media (min-width: 992px) {
  .md\:justify-self-start {
    justify-self: start;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-self-start {
    justify-self: start;
  }
}
@media (min-width: 576px) {
  .sm\:justify-self-end {
    justify-self: end;
  }
}
@media (min-width: 992px) {
  .md\:justify-self-end {
    justify-self: end;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-self-end {
    justify-self: end;
  }
}
@media (min-width: 576px) {
  .sm\:justify-self-center {
    justify-self: center;
  }
}
@media (min-width: 992px) {
  .md\:justify-self-center {
    justify-self: center;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-self-center {
    justify-self: center;
  }
}
@media (min-width: 576px) {
  .sm\:justify-self-stretch {
    justify-self: stretch;
  }
}
@media (min-width: 992px) {
  .md\:justify-self-stretch {
    justify-self: stretch;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-self-stretch {
    justify-self: stretch;
  }
}
@media (min-width: 576px) {
  .sm\:align-self-auto {
    align-self: auto;
  }
}
@media (min-width: 992px) {
  .md\:align-self-auto {
    align-self: auto;
  }
}
@media (min-width: 1200px) {
  .lg\:align-self-auto {
    align-self: auto;
  }
}
@media (min-width: 576px) {
  .sm\:align-self-start {
    align-self: flex-start;
  }
}
@media (min-width: 992px) {
  .md\:align-self-start {
    align-self: flex-start;
  }
}
@media (min-width: 1200px) {
  .lg\:align-self-start {
    align-self: flex-start;
  }
}
@media (min-width: 576px) {
  .sm\:align-self-end {
    align-self: flex-end;
  }
}
@media (min-width: 992px) {
  .md\:align-self-end {
    align-self: flex-end;
  }
}
@media (min-width: 1200px) {
  .lg\:align-self-end {
    align-self: flex-end;
  }
}
@media (min-width: 576px) {
  .sm\:align-self-center {
    align-self: center;
  }
}
@media (min-width: 992px) {
  .md\:align-self-center {
    align-self: center;
  }
}
@media (min-width: 1200px) {
  .lg\:align-self-center {
    align-self: center;
  }
}
@media (min-width: 576px) {
  .sm\:align-self-stretch {
    align-self: stretch;
  }
}
@media (min-width: 992px) {
  .md\:align-self-stretch {
    align-self: stretch;
  }
}
@media (min-width: 1200px) {
  .lg\:align-self-stretch {
    align-self: stretch;
  }
}
@media (min-width: 576px) {
  .sm\:align-top {
    vertical-align: top;
  }
}
@media (min-width: 992px) {
  .md\:align-top {
    vertical-align: top;
  }
}
@media (min-width: 1200px) {
  .lg\:align-top {
    vertical-align: top;
  }
}
@media (min-width: 576px) {
  .sm\:align-bottom {
    vertical-align: bottom;
  }
}
@media (min-width: 992px) {
  .md\:align-bottom {
    vertical-align: bottom;
  }
}
@media (min-width: 1200px) {
  .lg\:align-bottom {
    vertical-align: bottom;
  }
}
@media (min-width: 576px) {
  .sm\:align-middle {
    vertical-align: middle;
  }
}
@media (min-width: 992px) {
  .md\:align-middle {
    vertical-align: middle;
  }
}
@media (min-width: 1200px) {
  .lg\:align-middle {
    vertical-align: middle;
  }
}
@media (min-width: 576px) {
  .sm\:item-first {
    order: -100;
  }
}
@media (min-width: 992px) {
  .md\:item-first {
    order: -100;
  }
}
@media (min-width: 1200px) {
  .lg\:item-first {
    order: -100;
  }
}
@media (min-width: 576px) {
  .sm\:item-last {
    order: 100;
  }
}
@media (min-width: 992px) {
  .md\:item-last {
    order: 100;
  }
}
@media (min-width: 1200px) {
  .lg\:item-last {
    order: 100;
  }
}
.is-unstyled {
  list-style: none;
  padding-left: 0;
}

.is-disabled,
[disabled] {
  opacity: 0.6;
  cursor: not-allowed !important;
  filter: grayscale(1);
}

.text-normal {
  font-weight: normal;
  font-style: normal;
  text-transform: none;
}

@media (min-width: 576px) {
  .sm\:visually-hidden {
    position: absolute !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
  }
  .sm\:is-unstyled {
    list-style: none;
    padding-left: 0;
  }
  .sm\:is-disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
    filter: grayscale(1);
  }
  .sm\:text-normal {
    font-weight: normal;
    font-style: normal;
    text-transform: none;
  }
}
@media (min-width: 992px) {
  .md\:visually-hidden {
    position: absolute !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
  }
  .md\:is-unstyled {
    list-style: none;
    padding-left: 0;
  }
  .md\:is-disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
    filter: grayscale(1);
  }
  .md\:text-normal {
    font-weight: normal;
    font-style: normal;
    text-transform: none;
  }
}
@media (min-width: 1200px) {
  .lg\:visually-hidden {
    position: absolute !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
  }
  .lg\:is-unstyled {
    list-style: none;
    padding-left: 0;
  }
  .lg\:is-disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
    filter: grayscale(1);
  }
  .lg\:text-normal {
    font-weight: normal;
    font-style: normal;
    text-transform: none;
  }
}
/* Font sizes utility classes */
.text-10 {
  font-size: 0.625rem;
}

.text-11 {
  font-size: 0.6875rem;
}

.text-12 {
  font-size: 0.75rem;
}

.text-14 {
  font-size: 0.875rem;
}

.text-16 {
  font-size: 1rem;
}

.text-18 {
  font-size: 1.125rem;
}

.text-20 {
  font-size: 1.25rem;
}

.text-22 {
  font-size: 1.375rem;
}

.text-24 {
  font-size: 1.5rem;
}

.text-28 {
  font-size: 1.75rem;
}

.text-30 {
  font-size: 1.875rem;
}

.text-34 {
  font-size: 2.125rem;
}

.text-36 {
  font-size: 2.25rem;
}

.text-base {
  font-size: 1rem;
}

.text-inherit {
  font-size: inherit;
}

@media (min-width: 576px) {
  .sm\:text-10 {
    font-size: 0.625rem;
  }
}
@media (min-width: 992px) {
  .md\:text-10 {
    font-size: 0.625rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-10 {
    font-size: 0.625rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-11 {
    font-size: 0.6875rem;
  }
}
@media (min-width: 992px) {
  .md\:text-11 {
    font-size: 0.6875rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-11 {
    font-size: 0.6875rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-12 {
    font-size: 0.75rem;
  }
}
@media (min-width: 992px) {
  .md\:text-12 {
    font-size: 0.75rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-12 {
    font-size: 0.75rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-14 {
    font-size: 0.875rem;
  }
}
@media (min-width: 992px) {
  .md\:text-14 {
    font-size: 0.875rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-14 {
    font-size: 0.875rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-16 {
    font-size: 1rem;
  }
}
@media (min-width: 992px) {
  .md\:text-16 {
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-16 {
    font-size: 1rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-18 {
    font-size: 1.125rem;
  }
}
@media (min-width: 992px) {
  .md\:text-18 {
    font-size: 1.125rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-18 {
    font-size: 1.125rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-20 {
    font-size: 1.25rem;
  }
}
@media (min-width: 992px) {
  .md\:text-20 {
    font-size: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-20 {
    font-size: 1.25rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-22 {
    font-size: 1.375rem;
  }
}
@media (min-width: 992px) {
  .md\:text-22 {
    font-size: 1.375rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-22 {
    font-size: 1.375rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-24 {
    font-size: 1.5rem;
  }
}
@media (min-width: 992px) {
  .md\:text-24 {
    font-size: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-24 {
    font-size: 1.5rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-28 {
    font-size: 1.75rem;
  }
}
@media (min-width: 992px) {
  .md\:text-28 {
    font-size: 1.75rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-28 {
    font-size: 1.75rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-30 {
    font-size: 1.875rem;
  }
}
@media (min-width: 992px) {
  .md\:text-30 {
    font-size: 1.875rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-30 {
    font-size: 1.875rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-34 {
    font-size: 2.125rem;
  }
}
@media (min-width: 992px) {
  .md\:text-34 {
    font-size: 2.125rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-34 {
    font-size: 2.125rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-36 {
    font-size: 2.25rem;
  }
}
@media (min-width: 992px) {
  .md\:text-36 {
    font-size: 2.25rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-36 {
    font-size: 2.25rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-base {
    font-size: 1rem;
  }
}
@media (min-width: 992px) {
  .md\:text-base {
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-base {
    font-size: 1rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-inherit {
    font-size: inherit;
  }
}
@media (min-width: 992px) {
  .md\:text-inherit {
    font-size: inherit;
  }
}
@media (min-width: 1200px) {
  .lg\:text-inherit {
    font-size: inherit;
  }
}
/* Spacers utility classes */
.p-0 {
  padding: 0;
}

.pt-0 {
  padding-top: 0;
}

.pr-0 {
  padding-right: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .sm\:p-0 {
    padding: 0;
  }
  .sm\:pt-0 {
    padding-top: 0;
  }
  .sm\:pr-0 {
    padding-right: 0;
  }
  .sm\:pb-0 {
    padding-bottom: 0;
  }
  .sm\:pl-0 {
    padding-left: 0;
  }
  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .sm\:m-0 {
    margin: 0;
  }
  .sm\:mt-0 {
    margin-top: 0;
  }
  .sm\:mr-0 {
    margin-right: 0;
  }
  .sm\:mb-0 {
    margin-bottom: 0;
  }
  .sm\:ml-0 {
    margin-left: 0;
  }
  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .sm\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .md\:p-0 {
    padding: 0;
  }
  .md\:pt-0 {
    padding-top: 0;
  }
  .md\:pr-0 {
    padding-right: 0;
  }
  .md\:pb-0 {
    padding-bottom: 0;
  }
  .md\:pl-0 {
    padding-left: 0;
  }
  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .md\:m-0 {
    margin: 0;
  }
  .md\:mt-0 {
    margin-top: 0;
  }
  .md\:mr-0 {
    margin-right: 0;
  }
  .md\:mb-0 {
    margin-bottom: 0;
  }
  .md\:ml-0 {
    margin-left: 0;
  }
  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .lg\:p-0 {
    padding: 0;
  }
  .lg\:pt-0 {
    padding-top: 0;
  }
  .lg\:pr-0 {
    padding-right: 0;
  }
  .lg\:pb-0 {
    padding-bottom: 0;
  }
  .lg\:pl-0 {
    padding-left: 0;
  }
  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .lg\:m-0 {
    margin: 0;
  }
  .lg\:mt-0 {
    margin-top: 0;
  }
  .lg\:mr-0 {
    margin-right: 0;
  }
  .lg\:mb-0 {
    margin-bottom: 0;
  }
  .lg\:ml-0 {
    margin-left: 0;
  }
  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.p-1 {
  padding: 1px;
}

.pt-1 {
  padding-top: 1px;
}

.pr-1 {
  padding-right: 1px;
}

.pb-1 {
  padding-bottom: 1px;
}

.pl-1 {
  padding-left: 1px;
}

.px-1 {
  padding-left: 1px;
  padding-right: 1px;
}

.py-1 {
  padding-top: 1px;
  padding-bottom: 1px;
}

.m-1 {
  margin: 1px;
}

.mt-1 {
  margin-top: 1px;
}

.mr-1 {
  margin-right: 1px;
}

.mb-1 {
  margin-bottom: 1px;
}

.ml-1 {
  margin-left: 1px;
}

.mx-1 {
  margin-left: 1px;
  margin-right: 1px;
}

.my-1 {
  margin-top: 1px;
  margin-bottom: 1px;
}

@media (min-width: 576px) {
  .sm\:p-1 {
    padding: 1px;
  }
  .sm\:pt-1 {
    padding-top: 1px;
  }
  .sm\:pr-1 {
    padding-right: 1px;
  }
  .sm\:pb-1 {
    padding-bottom: 1px;
  }
  .sm\:pl-1 {
    padding-left: 1px;
  }
  .sm\:px-1 {
    padding-left: 1px;
    padding-right: 1px;
  }
  .sm\:py-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .sm\:m-1 {
    margin: 1px;
  }
  .sm\:mt-1 {
    margin-top: 1px;
  }
  .sm\:mr-1 {
    margin-right: 1px;
  }
  .sm\:mb-1 {
    margin-bottom: 1px;
  }
  .sm\:ml-1 {
    margin-left: 1px;
  }
  .sm\:mx-1 {
    margin-left: 1px;
    margin-right: 1px;
  }
  .sm\:my-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }
}
@media (min-width: 992px) {
  .md\:p-1 {
    padding: 1px;
  }
  .md\:pt-1 {
    padding-top: 1px;
  }
  .md\:pr-1 {
    padding-right: 1px;
  }
  .md\:pb-1 {
    padding-bottom: 1px;
  }
  .md\:pl-1 {
    padding-left: 1px;
  }
  .md\:px-1 {
    padding-left: 1px;
    padding-right: 1px;
  }
  .md\:py-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .md\:m-1 {
    margin: 1px;
  }
  .md\:mt-1 {
    margin-top: 1px;
  }
  .md\:mr-1 {
    margin-right: 1px;
  }
  .md\:mb-1 {
    margin-bottom: 1px;
  }
  .md\:ml-1 {
    margin-left: 1px;
  }
  .md\:mx-1 {
    margin-left: 1px;
    margin-right: 1px;
  }
  .md\:my-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }
}
@media (min-width: 1200px) {
  .lg\:p-1 {
    padding: 1px;
  }
  .lg\:pt-1 {
    padding-top: 1px;
  }
  .lg\:pr-1 {
    padding-right: 1px;
  }
  .lg\:pb-1 {
    padding-bottom: 1px;
  }
  .lg\:pl-1 {
    padding-left: 1px;
  }
  .lg\:px-1 {
    padding-left: 1px;
    padding-right: 1px;
  }
  .lg\:py-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .lg\:m-1 {
    margin: 1px;
  }
  .lg\:mt-1 {
    margin-top: 1px;
  }
  .lg\:mr-1 {
    margin-right: 1px;
  }
  .lg\:mb-1 {
    margin-bottom: 1px;
  }
  .lg\:ml-1 {
    margin-left: 1px;
  }
  .lg\:mx-1 {
    margin-left: 1px;
    margin-right: 1px;
  }
  .lg\:my-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }
}
.p-2 {
  padding: 0.125rem;
}

.pt-2 {
  padding-top: 0.125rem;
}

.pr-2 {
  padding-right: 0.125rem;
}

.pb-2 {
  padding-bottom: 0.125rem;
}

.pl-2 {
  padding-left: 0.125rem;
}

.px-2 {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

.py-2 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.m-2 {
  margin: 0.125rem;
}

.mt-2 {
  margin-top: 0.125rem;
}

.mr-2 {
  margin-right: 0.125rem;
}

.mb-2 {
  margin-bottom: 0.125rem;
}

.ml-2 {
  margin-left: 0.125rem;
}

.mx-2 {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

.my-2 {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}

@media (min-width: 576px) {
  .sm\:p-2 {
    padding: 0.125rem;
  }
  .sm\:pt-2 {
    padding-top: 0.125rem;
  }
  .sm\:pr-2 {
    padding-right: 0.125rem;
  }
  .sm\:pb-2 {
    padding-bottom: 0.125rem;
  }
  .sm\:pl-2 {
    padding-left: 0.125rem;
  }
  .sm\:px-2 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .sm\:py-2 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .sm\:m-2 {
    margin: 0.125rem;
  }
  .sm\:mt-2 {
    margin-top: 0.125rem;
  }
  .sm\:mr-2 {
    margin-right: 0.125rem;
  }
  .sm\:mb-2 {
    margin-bottom: 0.125rem;
  }
  .sm\:ml-2 {
    margin-left: 0.125rem;
  }
  .sm\:mx-2 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .sm\:my-2 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
}
@media (min-width: 992px) {
  .md\:p-2 {
    padding: 0.125rem;
  }
  .md\:pt-2 {
    padding-top: 0.125rem;
  }
  .md\:pr-2 {
    padding-right: 0.125rem;
  }
  .md\:pb-2 {
    padding-bottom: 0.125rem;
  }
  .md\:pl-2 {
    padding-left: 0.125rem;
  }
  .md\:px-2 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .md\:py-2 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .md\:m-2 {
    margin: 0.125rem;
  }
  .md\:mt-2 {
    margin-top: 0.125rem;
  }
  .md\:mr-2 {
    margin-right: 0.125rem;
  }
  .md\:mb-2 {
    margin-bottom: 0.125rem;
  }
  .md\:ml-2 {
    margin-left: 0.125rem;
  }
  .md\:mx-2 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .md\:my-2 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
}
@media (min-width: 1200px) {
  .lg\:p-2 {
    padding: 0.125rem;
  }
  .lg\:pt-2 {
    padding-top: 0.125rem;
  }
  .lg\:pr-2 {
    padding-right: 0.125rem;
  }
  .lg\:pb-2 {
    padding-bottom: 0.125rem;
  }
  .lg\:pl-2 {
    padding-left: 0.125rem;
  }
  .lg\:px-2 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .lg\:py-2 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .lg\:m-2 {
    margin: 0.125rem;
  }
  .lg\:mt-2 {
    margin-top: 0.125rem;
  }
  .lg\:mr-2 {
    margin-right: 0.125rem;
  }
  .lg\:mb-2 {
    margin-bottom: 0.125rem;
  }
  .lg\:ml-2 {
    margin-left: 0.125rem;
  }
  .lg\:mx-2 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .lg\:my-2 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
}
.p-4 {
  padding: 0.25rem;
}

.pt-4 {
  padding-top: 0.25rem;
}

.pr-4 {
  padding-right: 0.25rem;
}

.pb-4 {
  padding-bottom: 0.25rem;
}

.pl-4 {
  padding-left: 0.25rem;
}

.px-4 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.py-4 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.m-4 {
  margin: 0.25rem;
}

.mt-4 {
  margin-top: 0.25rem;
}

.mr-4 {
  margin-right: 0.25rem;
}

.mb-4 {
  margin-bottom: 0.25rem;
}

.ml-4 {
  margin-left: 0.25rem;
}

.mx-4 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.my-4 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

@media (min-width: 576px) {
  .sm\:p-4 {
    padding: 0.25rem;
  }
  .sm\:pt-4 {
    padding-top: 0.25rem;
  }
  .sm\:pr-4 {
    padding-right: 0.25rem;
  }
  .sm\:pb-4 {
    padding-bottom: 0.25rem;
  }
  .sm\:pl-4 {
    padding-left: 0.25rem;
  }
  .sm\:px-4 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .sm\:py-4 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .sm\:m-4 {
    margin: 0.25rem;
  }
  .sm\:mt-4 {
    margin-top: 0.25rem;
  }
  .sm\:mr-4 {
    margin-right: 0.25rem;
  }
  .sm\:mb-4 {
    margin-bottom: 0.25rem;
  }
  .sm\:ml-4 {
    margin-left: 0.25rem;
  }
  .sm\:mx-4 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .sm\:my-4 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}
@media (min-width: 992px) {
  .md\:p-4 {
    padding: 0.25rem;
  }
  .md\:pt-4 {
    padding-top: 0.25rem;
  }
  .md\:pr-4 {
    padding-right: 0.25rem;
  }
  .md\:pb-4 {
    padding-bottom: 0.25rem;
  }
  .md\:pl-4 {
    padding-left: 0.25rem;
  }
  .md\:px-4 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .md\:py-4 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .md\:m-4 {
    margin: 0.25rem;
  }
  .md\:mt-4 {
    margin-top: 0.25rem;
  }
  .md\:mr-4 {
    margin-right: 0.25rem;
  }
  .md\:mb-4 {
    margin-bottom: 0.25rem;
  }
  .md\:ml-4 {
    margin-left: 0.25rem;
  }
  .md\:mx-4 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .md\:my-4 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}
@media (min-width: 1200px) {
  .lg\:p-4 {
    padding: 0.25rem;
  }
  .lg\:pt-4 {
    padding-top: 0.25rem;
  }
  .lg\:pr-4 {
    padding-right: 0.25rem;
  }
  .lg\:pb-4 {
    padding-bottom: 0.25rem;
  }
  .lg\:pl-4 {
    padding-left: 0.25rem;
  }
  .lg\:px-4 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .lg\:py-4 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .lg\:m-4 {
    margin: 0.25rem;
  }
  .lg\:mt-4 {
    margin-top: 0.25rem;
  }
  .lg\:mr-4 {
    margin-right: 0.25rem;
  }
  .lg\:mb-4 {
    margin-bottom: 0.25rem;
  }
  .lg\:ml-4 {
    margin-left: 0.25rem;
  }
  .lg\:mx-4 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .lg\:my-4 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}
.p-8 {
  padding: 0.5rem;
}

.pt-8 {
  padding-top: 0.5rem;
}

.pr-8 {
  padding-right: 0.5rem;
}

.pb-8 {
  padding-bottom: 0.5rem;
}

.pl-8 {
  padding-left: 0.5rem;
}

.px-8 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-8 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.m-8 {
  margin: 0.5rem;
}

.mt-8 {
  margin-top: 0.5rem;
}

.mr-8 {
  margin-right: 0.5rem;
}

.mb-8 {
  margin-bottom: 0.5rem;
}

.ml-8 {
  margin-left: 0.5rem;
}

.mx-8 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.my-8 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

@media (min-width: 576px) {
  .sm\:p-8 {
    padding: 0.5rem;
  }
  .sm\:pt-8 {
    padding-top: 0.5rem;
  }
  .sm\:pr-8 {
    padding-right: 0.5rem;
  }
  .sm\:pb-8 {
    padding-bottom: 0.5rem;
  }
  .sm\:pl-8 {
    padding-left: 0.5rem;
  }
  .sm\:px-8 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .sm\:py-8 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .sm\:m-8 {
    margin: 0.5rem;
  }
  .sm\:mt-8 {
    margin-top: 0.5rem;
  }
  .sm\:mr-8 {
    margin-right: 0.5rem;
  }
  .sm\:mb-8 {
    margin-bottom: 0.5rem;
  }
  .sm\:ml-8 {
    margin-left: 0.5rem;
  }
  .sm\:mx-8 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .sm\:my-8 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 992px) {
  .md\:p-8 {
    padding: 0.5rem;
  }
  .md\:pt-8 {
    padding-top: 0.5rem;
  }
  .md\:pr-8 {
    padding-right: 0.5rem;
  }
  .md\:pb-8 {
    padding-bottom: 0.5rem;
  }
  .md\:pl-8 {
    padding-left: 0.5rem;
  }
  .md\:px-8 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .md\:py-8 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .md\:m-8 {
    margin: 0.5rem;
  }
  .md\:mt-8 {
    margin-top: 0.5rem;
  }
  .md\:mr-8 {
    margin-right: 0.5rem;
  }
  .md\:mb-8 {
    margin-bottom: 0.5rem;
  }
  .md\:ml-8 {
    margin-left: 0.5rem;
  }
  .md\:mx-8 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .md\:my-8 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 1200px) {
  .lg\:p-8 {
    padding: 0.5rem;
  }
  .lg\:pt-8 {
    padding-top: 0.5rem;
  }
  .lg\:pr-8 {
    padding-right: 0.5rem;
  }
  .lg\:pb-8 {
    padding-bottom: 0.5rem;
  }
  .lg\:pl-8 {
    padding-left: 0.5rem;
  }
  .lg\:px-8 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .lg\:py-8 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .lg\:m-8 {
    margin: 0.5rem;
  }
  .lg\:mt-8 {
    margin-top: 0.5rem;
  }
  .lg\:mr-8 {
    margin-right: 0.5rem;
  }
  .lg\:mb-8 {
    margin-bottom: 0.5rem;
  }
  .lg\:ml-8 {
    margin-left: 0.5rem;
  }
  .lg\:mx-8 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .lg\:my-8 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
.p-12 {
  padding: 0.75rem;
}

.pt-12 {
  padding-top: 0.75rem;
}

.pr-12 {
  padding-right: 0.75rem;
}

.pb-12 {
  padding-bottom: 0.75rem;
}

.pl-12 {
  padding-left: 0.75rem;
}

.px-12 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-12 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.m-12 {
  margin: 0.75rem;
}

.mt-12 {
  margin-top: 0.75rem;
}

.mr-12 {
  margin-right: 0.75rem;
}

.mb-12 {
  margin-bottom: 0.75rem;
}

.ml-12 {
  margin-left: 0.75rem;
}

.mx-12 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.my-12 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .sm\:p-12 {
    padding: 0.75rem;
  }
  .sm\:pt-12 {
    padding-top: 0.75rem;
  }
  .sm\:pr-12 {
    padding-right: 0.75rem;
  }
  .sm\:pb-12 {
    padding-bottom: 0.75rem;
  }
  .sm\:pl-12 {
    padding-left: 0.75rem;
  }
  .sm\:px-12 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .sm\:py-12 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .sm\:m-12 {
    margin: 0.75rem;
  }
  .sm\:mt-12 {
    margin-top: 0.75rem;
  }
  .sm\:mr-12 {
    margin-right: 0.75rem;
  }
  .sm\:mb-12 {
    margin-bottom: 0.75rem;
  }
  .sm\:ml-12 {
    margin-left: 0.75rem;
  }
  .sm\:mx-12 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .sm\:my-12 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 992px) {
  .md\:p-12 {
    padding: 0.75rem;
  }
  .md\:pt-12 {
    padding-top: 0.75rem;
  }
  .md\:pr-12 {
    padding-right: 0.75rem;
  }
  .md\:pb-12 {
    padding-bottom: 0.75rem;
  }
  .md\:pl-12 {
    padding-left: 0.75rem;
  }
  .md\:px-12 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .md\:py-12 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .md\:m-12 {
    margin: 0.75rem;
  }
  .md\:mt-12 {
    margin-top: 0.75rem;
  }
  .md\:mr-12 {
    margin-right: 0.75rem;
  }
  .md\:mb-12 {
    margin-bottom: 0.75rem;
  }
  .md\:ml-12 {
    margin-left: 0.75rem;
  }
  .md\:mx-12 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .md\:my-12 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 1200px) {
  .lg\:p-12 {
    padding: 0.75rem;
  }
  .lg\:pt-12 {
    padding-top: 0.75rem;
  }
  .lg\:pr-12 {
    padding-right: 0.75rem;
  }
  .lg\:pb-12 {
    padding-bottom: 0.75rem;
  }
  .lg\:pl-12 {
    padding-left: 0.75rem;
  }
  .lg\:px-12 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .lg\:py-12 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .lg\:m-12 {
    margin: 0.75rem;
  }
  .lg\:mt-12 {
    margin-top: 0.75rem;
  }
  .lg\:mr-12 {
    margin-right: 0.75rem;
  }
  .lg\:mb-12 {
    margin-bottom: 0.75rem;
  }
  .lg\:ml-12 {
    margin-left: 0.75rem;
  }
  .lg\:mx-12 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .lg\:my-12 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
}
.p-16 {
  padding: 1rem;
}

.pt-16 {
  padding-top: 1rem;
}

.pr-16 {
  padding-right: 1rem;
}

.pb-16 {
  padding-bottom: 1rem;
}

.pl-16 {
  padding-left: 1rem;
}

.px-16 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-16 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.m-16 {
  margin: 1rem;
}

.mt-16 {
  margin-top: 1rem;
}

.mr-16 {
  margin-right: 1rem;
}

.mb-16 {
  margin-bottom: 1rem;
}

.ml-16 {
  margin-left: 1rem;
}

.mx-16 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-16 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .sm\:p-16 {
    padding: 1rem;
  }
  .sm\:pt-16 {
    padding-top: 1rem;
  }
  .sm\:pr-16 {
    padding-right: 1rem;
  }
  .sm\:pb-16 {
    padding-bottom: 1rem;
  }
  .sm\:pl-16 {
    padding-left: 1rem;
  }
  .sm\:px-16 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .sm\:py-16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .sm\:m-16 {
    margin: 1rem;
  }
  .sm\:mt-16 {
    margin-top: 1rem;
  }
  .sm\:mr-16 {
    margin-right: 1rem;
  }
  .sm\:mb-16 {
    margin-bottom: 1rem;
  }
  .sm\:ml-16 {
    margin-left: 1rem;
  }
  .sm\:mx-16 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .sm\:my-16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media (min-width: 992px) {
  .md\:p-16 {
    padding: 1rem;
  }
  .md\:pt-16 {
    padding-top: 1rem;
  }
  .md\:pr-16 {
    padding-right: 1rem;
  }
  .md\:pb-16 {
    padding-bottom: 1rem;
  }
  .md\:pl-16 {
    padding-left: 1rem;
  }
  .md\:px-16 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .md\:py-16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .md\:m-16 {
    margin: 1rem;
  }
  .md\:mt-16 {
    margin-top: 1rem;
  }
  .md\:mr-16 {
    margin-right: 1rem;
  }
  .md\:mb-16 {
    margin-bottom: 1rem;
  }
  .md\:ml-16 {
    margin-left: 1rem;
  }
  .md\:mx-16 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .md\:my-16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media (min-width: 1200px) {
  .lg\:p-16 {
    padding: 1rem;
  }
  .lg\:pt-16 {
    padding-top: 1rem;
  }
  .lg\:pr-16 {
    padding-right: 1rem;
  }
  .lg\:pb-16 {
    padding-bottom: 1rem;
  }
  .lg\:pl-16 {
    padding-left: 1rem;
  }
  .lg\:px-16 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .lg\:py-16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .lg\:m-16 {
    margin: 1rem;
  }
  .lg\:mt-16 {
    margin-top: 1rem;
  }
  .lg\:mr-16 {
    margin-right: 1rem;
  }
  .lg\:mb-16 {
    margin-bottom: 1rem;
  }
  .lg\:ml-16 {
    margin-left: 1rem;
  }
  .lg\:mx-16 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .lg\:my-16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
.p-20 {
  padding: 1.25rem;
}

.pt-20 {
  padding-top: 1.25rem;
}

.pr-20 {
  padding-right: 1.25rem;
}

.pb-20 {
  padding-bottom: 1.25rem;
}

.pl-20 {
  padding-left: 1.25rem;
}

.px-20 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-20 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.m-20 {
  margin: 1.25rem;
}

.mt-20 {
  margin-top: 1.25rem;
}

.mr-20 {
  margin-right: 1.25rem;
}

.mb-20 {
  margin-bottom: 1.25rem;
}

.ml-20 {
  margin-left: 1.25rem;
}

.mx-20 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.my-20 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

@media (min-width: 576px) {
  .sm\:p-20 {
    padding: 1.25rem;
  }
  .sm\:pt-20 {
    padding-top: 1.25rem;
  }
  .sm\:pr-20 {
    padding-right: 1.25rem;
  }
  .sm\:pb-20 {
    padding-bottom: 1.25rem;
  }
  .sm\:pl-20 {
    padding-left: 1.25rem;
  }
  .sm\:px-20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .sm\:py-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .sm\:m-20 {
    margin: 1.25rem;
  }
  .sm\:mt-20 {
    margin-top: 1.25rem;
  }
  .sm\:mr-20 {
    margin-right: 1.25rem;
  }
  .sm\:mb-20 {
    margin-bottom: 1.25rem;
  }
  .sm\:ml-20 {
    margin-left: 1.25rem;
  }
  .sm\:mx-20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .sm\:my-20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 992px) {
  .md\:p-20 {
    padding: 1.25rem;
  }
  .md\:pt-20 {
    padding-top: 1.25rem;
  }
  .md\:pr-20 {
    padding-right: 1.25rem;
  }
  .md\:pb-20 {
    padding-bottom: 1.25rem;
  }
  .md\:pl-20 {
    padding-left: 1.25rem;
  }
  .md\:px-20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .md\:py-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .md\:m-20 {
    margin: 1.25rem;
  }
  .md\:mt-20 {
    margin-top: 1.25rem;
  }
  .md\:mr-20 {
    margin-right: 1.25rem;
  }
  .md\:mb-20 {
    margin-bottom: 1.25rem;
  }
  .md\:ml-20 {
    margin-left: 1.25rem;
  }
  .md\:mx-20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .md\:my-20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .lg\:p-20 {
    padding: 1.25rem;
  }
  .lg\:pt-20 {
    padding-top: 1.25rem;
  }
  .lg\:pr-20 {
    padding-right: 1.25rem;
  }
  .lg\:pb-20 {
    padding-bottom: 1.25rem;
  }
  .lg\:pl-20 {
    padding-left: 1.25rem;
  }
  .lg\:px-20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .lg\:py-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .lg\:m-20 {
    margin: 1.25rem;
  }
  .lg\:mt-20 {
    margin-top: 1.25rem;
  }
  .lg\:mr-20 {
    margin-right: 1.25rem;
  }
  .lg\:mb-20 {
    margin-bottom: 1.25rem;
  }
  .lg\:ml-20 {
    margin-left: 1.25rem;
  }
  .lg\:mx-20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .lg\:my-20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
}
.p-24 {
  padding: 1.5rem;
}

.pt-24 {
  padding-top: 1.5rem;
}

.pr-24 {
  padding-right: 1.5rem;
}

.pb-24 {
  padding-bottom: 1.5rem;
}

.pl-24 {
  padding-left: 1.5rem;
}

.px-24 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-24 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.m-24 {
  margin: 1.5rem;
}

.mt-24 {
  margin-top: 1.5rem;
}

.mr-24 {
  margin-right: 1.5rem;
}

.mb-24 {
  margin-bottom: 1.5rem;
}

.ml-24 {
  margin-left: 1.5rem;
}

.mx-24 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.my-24 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 576px) {
  .sm\:p-24 {
    padding: 1.5rem;
  }
  .sm\:pt-24 {
    padding-top: 1.5rem;
  }
  .sm\:pr-24 {
    padding-right: 1.5rem;
  }
  .sm\:pb-24 {
    padding-bottom: 1.5rem;
  }
  .sm\:pl-24 {
    padding-left: 1.5rem;
  }
  .sm\:px-24 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .sm\:py-24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .sm\:m-24 {
    margin: 1.5rem;
  }
  .sm\:mt-24 {
    margin-top: 1.5rem;
  }
  .sm\:mr-24 {
    margin-right: 1.5rem;
  }
  .sm\:mb-24 {
    margin-bottom: 1.5rem;
  }
  .sm\:ml-24 {
    margin-left: 1.5rem;
  }
  .sm\:mx-24 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .sm\:my-24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 992px) {
  .md\:p-24 {
    padding: 1.5rem;
  }
  .md\:pt-24 {
    padding-top: 1.5rem;
  }
  .md\:pr-24 {
    padding-right: 1.5rem;
  }
  .md\:pb-24 {
    padding-bottom: 1.5rem;
  }
  .md\:pl-24 {
    padding-left: 1.5rem;
  }
  .md\:px-24 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .md\:py-24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .md\:m-24 {
    margin: 1.5rem;
  }
  .md\:mt-24 {
    margin-top: 1.5rem;
  }
  .md\:mr-24 {
    margin-right: 1.5rem;
  }
  .md\:mb-24 {
    margin-bottom: 1.5rem;
  }
  .md\:ml-24 {
    margin-left: 1.5rem;
  }
  .md\:mx-24 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .md\:my-24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .lg\:p-24 {
    padding: 1.5rem;
  }
  .lg\:pt-24 {
    padding-top: 1.5rem;
  }
  .lg\:pr-24 {
    padding-right: 1.5rem;
  }
  .lg\:pb-24 {
    padding-bottom: 1.5rem;
  }
  .lg\:pl-24 {
    padding-left: 1.5rem;
  }
  .lg\:px-24 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .lg\:py-24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .lg\:m-24 {
    margin: 1.5rem;
  }
  .lg\:mt-24 {
    margin-top: 1.5rem;
  }
  .lg\:mr-24 {
    margin-right: 1.5rem;
  }
  .lg\:mb-24 {
    margin-bottom: 1.5rem;
  }
  .lg\:ml-24 {
    margin-left: 1.5rem;
  }
  .lg\:mx-24 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .lg\:my-24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
.p-36 {
  padding: 2.25rem;
}

.pt-36 {
  padding-top: 2.25rem;
}

.pr-36 {
  padding-right: 2.25rem;
}

.pb-36 {
  padding-bottom: 2.25rem;
}

.pl-36 {
  padding-left: 2.25rem;
}

.px-36 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.py-36 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.m-36 {
  margin: 2.25rem;
}

.mt-36 {
  margin-top: 2.25rem;
}

.mr-36 {
  margin-right: 2.25rem;
}

.mb-36 {
  margin-bottom: 2.25rem;
}

.ml-36 {
  margin-left: 2.25rem;
}

.mx-36 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

.my-36 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

@media (min-width: 576px) {
  .sm\:p-36 {
    padding: 2.25rem;
  }
  .sm\:pt-36 {
    padding-top: 2.25rem;
  }
  .sm\:pr-36 {
    padding-right: 2.25rem;
  }
  .sm\:pb-36 {
    padding-bottom: 2.25rem;
  }
  .sm\:pl-36 {
    padding-left: 2.25rem;
  }
  .sm\:px-36 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .sm\:py-36 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .sm\:m-36 {
    margin: 2.25rem;
  }
  .sm\:mt-36 {
    margin-top: 2.25rem;
  }
  .sm\:mr-36 {
    margin-right: 2.25rem;
  }
  .sm\:mb-36 {
    margin-bottom: 2.25rem;
  }
  .sm\:ml-36 {
    margin-left: 2.25rem;
  }
  .sm\:mx-36 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .sm\:my-36 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
}
@media (min-width: 992px) {
  .md\:p-36 {
    padding: 2.25rem;
  }
  .md\:pt-36 {
    padding-top: 2.25rem;
  }
  .md\:pr-36 {
    padding-right: 2.25rem;
  }
  .md\:pb-36 {
    padding-bottom: 2.25rem;
  }
  .md\:pl-36 {
    padding-left: 2.25rem;
  }
  .md\:px-36 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .md\:py-36 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .md\:m-36 {
    margin: 2.25rem;
  }
  .md\:mt-36 {
    margin-top: 2.25rem;
  }
  .md\:mr-36 {
    margin-right: 2.25rem;
  }
  .md\:mb-36 {
    margin-bottom: 2.25rem;
  }
  .md\:ml-36 {
    margin-left: 2.25rem;
  }
  .md\:mx-36 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .md\:my-36 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
}
@media (min-width: 1200px) {
  .lg\:p-36 {
    padding: 2.25rem;
  }
  .lg\:pt-36 {
    padding-top: 2.25rem;
  }
  .lg\:pr-36 {
    padding-right: 2.25rem;
  }
  .lg\:pb-36 {
    padding-bottom: 2.25rem;
  }
  .lg\:pl-36 {
    padding-left: 2.25rem;
  }
  .lg\:px-36 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .lg\:py-36 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .lg\:m-36 {
    margin: 2.25rem;
  }
  .lg\:mt-36 {
    margin-top: 2.25rem;
  }
  .lg\:mr-36 {
    margin-right: 2.25rem;
  }
  .lg\:mb-36 {
    margin-bottom: 2.25rem;
  }
  .lg\:ml-36 {
    margin-left: 2.25rem;
  }
  .lg\:mx-36 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .lg\:my-36 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
}
.p-auto {
  padding: auto;
}

.pt-auto {
  padding-top: auto;
}

.pr-auto {
  padding-right: auto;
}

.pb-auto {
  padding-bottom: auto;
}

.pl-auto {
  padding-left: auto;
}

.px-auto {
  padding-left: auto;
  padding-right: auto;
}

.py-auto {
  padding-top: auto;
  padding-bottom: auto;
}

.m-auto {
  margin: auto;
}

.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

@media (min-width: 576px) {
  .sm\:p-auto {
    padding: auto;
  }
  .sm\:pt-auto {
    padding-top: auto;
  }
  .sm\:pr-auto {
    padding-right: auto;
  }
  .sm\:pb-auto {
    padding-bottom: auto;
  }
  .sm\:pl-auto {
    padding-left: auto;
  }
  .sm\:px-auto {
    padding-left: auto;
    padding-right: auto;
  }
  .sm\:py-auto {
    padding-top: auto;
    padding-bottom: auto;
  }
  .sm\:m-auto {
    margin: auto;
  }
  .sm\:mt-auto {
    margin-top: auto;
  }
  .sm\:mr-auto {
    margin-right: auto;
  }
  .sm\:mb-auto {
    margin-bottom: auto;
  }
  .sm\:ml-auto {
    margin-left: auto;
  }
  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .sm\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
}
@media (min-width: 992px) {
  .md\:p-auto {
    padding: auto;
  }
  .md\:pt-auto {
    padding-top: auto;
  }
  .md\:pr-auto {
    padding-right: auto;
  }
  .md\:pb-auto {
    padding-bottom: auto;
  }
  .md\:pl-auto {
    padding-left: auto;
  }
  .md\:px-auto {
    padding-left: auto;
    padding-right: auto;
  }
  .md\:py-auto {
    padding-top: auto;
    padding-bottom: auto;
  }
  .md\:m-auto {
    margin: auto;
  }
  .md\:mt-auto {
    margin-top: auto;
  }
  .md\:mr-auto {
    margin-right: auto;
  }
  .md\:mb-auto {
    margin-bottom: auto;
  }
  .md\:ml-auto {
    margin-left: auto;
  }
  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .md\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
}
@media (min-width: 1200px) {
  .lg\:p-auto {
    padding: auto;
  }
  .lg\:pt-auto {
    padding-top: auto;
  }
  .lg\:pr-auto {
    padding-right: auto;
  }
  .lg\:pb-auto {
    padding-bottom: auto;
  }
  .lg\:pl-auto {
    padding-left: auto;
  }
  .lg\:px-auto {
    padding-left: auto;
    padding-right: auto;
  }
  .lg\:py-auto {
    padding-top: auto;
    padding-bottom: auto;
  }
  .lg\:m-auto {
    margin: auto;
  }
  .lg\:mt-auto {
    margin-top: auto;
  }
  .lg\:mr-auto {
    margin-right: auto;
  }
  .lg\:mb-auto {
    margin-bottom: auto;
  }
  .lg\:ml-auto {
    margin-left: auto;
  }
  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .lg\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
}
/* -------------------------------------------------- */
/* ==Grillade : système de micro-grille  de KNACSS   */
/* ------------------------------------------------ */
.grid {
  display: grid;
}

@media (min-width: 576px) {
  .sm\:grid {
    display: grid;
  }
}
@media (min-width: 992px) {
  .md\:grid {
    display: grid;
  }
}
@media (min-width: 1200px) {
  .lg\:grid {
    display: grid;
  }
}
/* grid-template-columns initialisation */
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

/* grid-template-columns au sein des breakpoints */
@media (min-width: 576px) {
  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}
@media (min-width: 992px) {
  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}
@media (min-width: 1200px) {
  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}
/* gap initialisation */
.gap-0 {
  gap: 0;
}

.gap-x-0 {
  column-gap: 0;
}

.gap-y-0 {
  row-gap: 0;
}

.gap-5 {
  gap: 0.313rem;
}

.gap-x-5 {
  column-gap: 0.313rem;
}

.gap-y-5 {
  row-gap: 0.313rem;
}

.gap-10 {
  gap: 0.625rem;
}

.gap-x-10 {
  column-gap: 0.625rem;
}

.gap-y-10 {
  row-gap: 0.625rem;
}

.gap-16 {
  gap: 1rem;
}

.gap-x-16 {
  column-gap: 1rem;
}

.gap-y-16 {
  row-gap: 1rem;
}

.gap-20 {
  gap: 1.25rem;
}

.gap-x-20 {
  column-gap: 1.25rem;
}

.gap-y-20 {
  row-gap: 1.25rem;
}

.gap-36 {
  gap: 2.25rem;
}

.gap-x-36 {
  column-gap: 2.25rem;
}

.gap-y-36 {
  row-gap: 2.25rem;
}

/* gap au sein des breakpoints */
@media (min-width: 576px) {
  .sm\:gap-0 {
    gap: 0;
  }
  .sm\:gap-x-0 {
    column-gap: 0;
  }
  .sm\:gap-y-0 {
    row-gap: 0;
  }
  .sm\:gap-5 {
    gap: 0.313rem;
  }
  .sm\:gap-x-5 {
    column-gap: 0.313rem;
  }
  .sm\:gap-y-5 {
    row-gap: 0.313rem;
  }
  .sm\:gap-10 {
    gap: 0.625rem;
  }
  .sm\:gap-x-10 {
    column-gap: 0.625rem;
  }
  .sm\:gap-y-10 {
    row-gap: 0.625rem;
  }
  .sm\:gap-16 {
    gap: 1rem;
  }
  .sm\:gap-x-16 {
    column-gap: 1rem;
  }
  .sm\:gap-y-16 {
    row-gap: 1rem;
  }
  .sm\:gap-20 {
    gap: 1.25rem;
  }
  .sm\:gap-x-20 {
    column-gap: 1.25rem;
  }
  .sm\:gap-y-20 {
    row-gap: 1.25rem;
  }
  .sm\:gap-36 {
    gap: 2.25rem;
  }
  .sm\:gap-x-36 {
    column-gap: 2.25rem;
  }
  .sm\:gap-y-36 {
    row-gap: 2.25rem;
  }
}
@media (min-width: 992px) {
  .md\:gap-0 {
    gap: 0;
  }
  .md\:gap-x-0 {
    column-gap: 0;
  }
  .md\:gap-y-0 {
    row-gap: 0;
  }
  .md\:gap-5 {
    gap: 0.313rem;
  }
  .md\:gap-x-5 {
    column-gap: 0.313rem;
  }
  .md\:gap-y-5 {
    row-gap: 0.313rem;
  }
  .md\:gap-10 {
    gap: 0.625rem;
  }
  .md\:gap-x-10 {
    column-gap: 0.625rem;
  }
  .md\:gap-y-10 {
    row-gap: 0.625rem;
  }
  .md\:gap-16 {
    gap: 1rem;
  }
  .md\:gap-x-16 {
    column-gap: 1rem;
  }
  .md\:gap-y-16 {
    row-gap: 1rem;
  }
  .md\:gap-20 {
    gap: 1.25rem;
  }
  .md\:gap-x-20 {
    column-gap: 1.25rem;
  }
  .md\:gap-y-20 {
    row-gap: 1.25rem;
  }
  .md\:gap-36 {
    gap: 2.25rem;
  }
  .md\:gap-x-36 {
    column-gap: 2.25rem;
  }
  .md\:gap-y-36 {
    row-gap: 2.25rem;
  }
}
@media (min-width: 1200px) {
  .lg\:gap-0 {
    gap: 0;
  }
  .lg\:gap-x-0 {
    column-gap: 0;
  }
  .lg\:gap-y-0 {
    row-gap: 0;
  }
  .lg\:gap-5 {
    gap: 0.313rem;
  }
  .lg\:gap-x-5 {
    column-gap: 0.313rem;
  }
  .lg\:gap-y-5 {
    row-gap: 0.313rem;
  }
  .lg\:gap-10 {
    gap: 0.625rem;
  }
  .lg\:gap-x-10 {
    column-gap: 0.625rem;
  }
  .lg\:gap-y-10 {
    row-gap: 0.625rem;
  }
  .lg\:gap-16 {
    gap: 1rem;
  }
  .lg\:gap-x-16 {
    column-gap: 1rem;
  }
  .lg\:gap-y-16 {
    row-gap: 1rem;
  }
  .lg\:gap-20 {
    gap: 1.25rem;
  }
  .lg\:gap-x-20 {
    column-gap: 1.25rem;
  }
  .lg\:gap-y-20 {
    row-gap: 1.25rem;
  }
  .lg\:gap-36 {
    gap: 2.25rem;
  }
  .lg\:gap-x-36 {
    column-gap: 2.25rem;
  }
  .lg\:gap-y-36 {
    row-gap: 2.25rem;
  }
}
/* grid-items initialisation */
.col-start-1 {
  grid-column-start: 1;
}

.col-end-1 {
  grid-column-end: 1;
}

.col-span-1 {
  grid-column: span 1/span 1;
}

.col-span-full {
  grid-column: 1/-1;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-end-1 {
  grid-row-end: 1;
}

.row-span-1 {
  grid-row: span 1/span 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-end-2 {
  grid-column-end: 2;
}

.col-span-2 {
  grid-column: span 2/span 2;
}

.col-span-full {
  grid-column: 1/-1;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-end-2 {
  grid-row-end: 2;
}

.row-span-2 {
  grid-row: span 2/span 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-end-3 {
  grid-column-end: 3;
}

.col-span-3 {
  grid-column: span 3/span 3;
}

.col-span-full {
  grid-column: 1/-1;
}

.row-start-3 {
  grid-row-start: 3;
}

.row-end-3 {
  grid-row-end: 3;
}

.row-span-3 {
  grid-row: span 3/span 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.col-end-4 {
  grid-column-end: 4;
}

.col-span-4 {
  grid-column: span 4/span 4;
}

.col-span-full {
  grid-column: 1/-1;
}

.row-start-4 {
  grid-row-start: 4;
}

.row-end-4 {
  grid-row-end: 4;
}

.row-span-4 {
  grid-row: span 4/span 4;
}

.col-start-5 {
  grid-column-start: 5;
}

.col-end-5 {
  grid-column-end: 5;
}

.col-span-5 {
  grid-column: span 5/span 5;
}

.col-span-full {
  grid-column: 1/-1;
}

.row-start-5 {
  grid-row-start: 5;
}

.row-end-5 {
  grid-row-end: 5;
}

.row-span-5 {
  grid-row: span 5/span 5;
}

.col-start-6 {
  grid-column-start: 6;
}

.col-end-6 {
  grid-column-end: 6;
}

.col-span-6 {
  grid-column: span 6/span 6;
}

.col-span-full {
  grid-column: 1/-1;
}

.row-start-6 {
  grid-row-start: 6;
}

.row-end-6 {
  grid-row-end: 6;
}

.row-span-6 {
  grid-row: span 6/span 6;
}

/* grid-items au sein des breakpoints */
@media (min-width: 576px) {
  .sm\:col-start-1 {
    grid-column-start: 1;
  }
  .sm\:col-end-1 {
    grid-column-end: 1;
  }
  .sm\:col-span-1 {
    grid-column: span 1/span 1;
  }
  .sm\:col-span-full {
    grid-column: 1/-1;
  }
  .sm\:row-start-1 {
    grid-row-start: 1;
  }
  .sm\:row-end-1 {
    grid-row-end: 1;
  }
  .sm\:row-span-1 {
    grid-row: span 1/span 1;
  }
  .sm\:col-start-2 {
    grid-column-start: 2;
  }
  .sm\:col-end-2 {
    grid-column-end: 2;
  }
  .sm\:col-span-2 {
    grid-column: span 2/span 2;
  }
  .sm\:col-span-full {
    grid-column: 1/-1;
  }
  .sm\:row-start-2 {
    grid-row-start: 2;
  }
  .sm\:row-end-2 {
    grid-row-end: 2;
  }
  .sm\:row-span-2 {
    grid-row: span 2/span 2;
  }
  .sm\:col-start-3 {
    grid-column-start: 3;
  }
  .sm\:col-end-3 {
    grid-column-end: 3;
  }
  .sm\:col-span-3 {
    grid-column: span 3/span 3;
  }
  .sm\:col-span-full {
    grid-column: 1/-1;
  }
  .sm\:row-start-3 {
    grid-row-start: 3;
  }
  .sm\:row-end-3 {
    grid-row-end: 3;
  }
  .sm\:row-span-3 {
    grid-row: span 3/span 3;
  }
  .sm\:col-start-4 {
    grid-column-start: 4;
  }
  .sm\:col-end-4 {
    grid-column-end: 4;
  }
  .sm\:col-span-4 {
    grid-column: span 4/span 4;
  }
  .sm\:col-span-full {
    grid-column: 1/-1;
  }
  .sm\:row-start-4 {
    grid-row-start: 4;
  }
  .sm\:row-end-4 {
    grid-row-end: 4;
  }
  .sm\:row-span-4 {
    grid-row: span 4/span 4;
  }
  .sm\:col-start-5 {
    grid-column-start: 5;
  }
  .sm\:col-end-5 {
    grid-column-end: 5;
  }
  .sm\:col-span-5 {
    grid-column: span 5/span 5;
  }
  .sm\:col-span-full {
    grid-column: 1/-1;
  }
  .sm\:row-start-5 {
    grid-row-start: 5;
  }
  .sm\:row-end-5 {
    grid-row-end: 5;
  }
  .sm\:row-span-5 {
    grid-row: span 5/span 5;
  }
  .sm\:col-start-6 {
    grid-column-start: 6;
  }
  .sm\:col-end-6 {
    grid-column-end: 6;
  }
  .sm\:col-span-6 {
    grid-column: span 6/span 6;
  }
  .sm\:col-span-full {
    grid-column: 1/-1;
  }
  .sm\:row-start-6 {
    grid-row-start: 6;
  }
  .sm\:row-end-6 {
    grid-row-end: 6;
  }
  .sm\:row-span-6 {
    grid-row: span 6/span 6;
  }
}
@media (min-width: 992px) {
  .md\:col-start-1 {
    grid-column-start: 1;
  }
  .md\:col-end-1 {
    grid-column-end: 1;
  }
  .md\:col-span-1 {
    grid-column: span 1/span 1;
  }
  .md\:col-span-full {
    grid-column: 1/-1;
  }
  .md\:row-start-1 {
    grid-row-start: 1;
  }
  .md\:row-end-1 {
    grid-row-end: 1;
  }
  .md\:row-span-1 {
    grid-row: span 1/span 1;
  }
  .md\:col-start-2 {
    grid-column-start: 2;
  }
  .md\:col-end-2 {
    grid-column-end: 2;
  }
  .md\:col-span-2 {
    grid-column: span 2/span 2;
  }
  .md\:col-span-full {
    grid-column: 1/-1;
  }
  .md\:row-start-2 {
    grid-row-start: 2;
  }
  .md\:row-end-2 {
    grid-row-end: 2;
  }
  .md\:row-span-2 {
    grid-row: span 2/span 2;
  }
  .md\:col-start-3 {
    grid-column-start: 3;
  }
  .md\:col-end-3 {
    grid-column-end: 3;
  }
  .md\:col-span-3 {
    grid-column: span 3/span 3;
  }
  .md\:col-span-full {
    grid-column: 1/-1;
  }
  .md\:row-start-3 {
    grid-row-start: 3;
  }
  .md\:row-end-3 {
    grid-row-end: 3;
  }
  .md\:row-span-3 {
    grid-row: span 3/span 3;
  }
  .md\:col-start-4 {
    grid-column-start: 4;
  }
  .md\:col-end-4 {
    grid-column-end: 4;
  }
  .md\:col-span-4 {
    grid-column: span 4/span 4;
  }
  .md\:col-span-full {
    grid-column: 1/-1;
  }
  .md\:row-start-4 {
    grid-row-start: 4;
  }
  .md\:row-end-4 {
    grid-row-end: 4;
  }
  .md\:row-span-4 {
    grid-row: span 4/span 4;
  }
  .md\:col-start-5 {
    grid-column-start: 5;
  }
  .md\:col-end-5 {
    grid-column-end: 5;
  }
  .md\:col-span-5 {
    grid-column: span 5/span 5;
  }
  .md\:col-span-full {
    grid-column: 1/-1;
  }
  .md\:row-start-5 {
    grid-row-start: 5;
  }
  .md\:row-end-5 {
    grid-row-end: 5;
  }
  .md\:row-span-5 {
    grid-row: span 5/span 5;
  }
  .md\:col-start-6 {
    grid-column-start: 6;
  }
  .md\:col-end-6 {
    grid-column-end: 6;
  }
  .md\:col-span-6 {
    grid-column: span 6/span 6;
  }
  .md\:col-span-full {
    grid-column: 1/-1;
  }
  .md\:row-start-6 {
    grid-row-start: 6;
  }
  .md\:row-end-6 {
    grid-row-end: 6;
  }
  .md\:row-span-6 {
    grid-row: span 6/span 6;
  }
}
@media (min-width: 1200px) {
  .lg\:col-start-1 {
    grid-column-start: 1;
  }
  .lg\:col-end-1 {
    grid-column-end: 1;
  }
  .lg\:col-span-1 {
    grid-column: span 1/span 1;
  }
  .lg\:col-span-full {
    grid-column: 1/-1;
  }
  .lg\:row-start-1 {
    grid-row-start: 1;
  }
  .lg\:row-end-1 {
    grid-row-end: 1;
  }
  .lg\:row-span-1 {
    grid-row: span 1/span 1;
  }
  .lg\:col-start-2 {
    grid-column-start: 2;
  }
  .lg\:col-end-2 {
    grid-column-end: 2;
  }
  .lg\:col-span-2 {
    grid-column: span 2/span 2;
  }
  .lg\:col-span-full {
    grid-column: 1/-1;
  }
  .lg\:row-start-2 {
    grid-row-start: 2;
  }
  .lg\:row-end-2 {
    grid-row-end: 2;
  }
  .lg\:row-span-2 {
    grid-row: span 2/span 2;
  }
  .lg\:col-start-3 {
    grid-column-start: 3;
  }
  .lg\:col-end-3 {
    grid-column-end: 3;
  }
  .lg\:col-span-3 {
    grid-column: span 3/span 3;
  }
  .lg\:col-span-full {
    grid-column: 1/-1;
  }
  .lg\:row-start-3 {
    grid-row-start: 3;
  }
  .lg\:row-end-3 {
    grid-row-end: 3;
  }
  .lg\:row-span-3 {
    grid-row: span 3/span 3;
  }
  .lg\:col-start-4 {
    grid-column-start: 4;
  }
  .lg\:col-end-4 {
    grid-column-end: 4;
  }
  .lg\:col-span-4 {
    grid-column: span 4/span 4;
  }
  .lg\:col-span-full {
    grid-column: 1/-1;
  }
  .lg\:row-start-4 {
    grid-row-start: 4;
  }
  .lg\:row-end-4 {
    grid-row-end: 4;
  }
  .lg\:row-span-4 {
    grid-row: span 4/span 4;
  }
  .lg\:col-start-5 {
    grid-column-start: 5;
  }
  .lg\:col-end-5 {
    grid-column-end: 5;
  }
  .lg\:col-span-5 {
    grid-column: span 5/span 5;
  }
  .lg\:col-span-full {
    grid-column: 1/-1;
  }
  .lg\:row-start-5 {
    grid-row-start: 5;
  }
  .lg\:row-end-5 {
    grid-row-end: 5;
  }
  .lg\:row-span-5 {
    grid-row: span 5/span 5;
  }
  .lg\:col-start-6 {
    grid-column-start: 6;
  }
  .lg\:col-end-6 {
    grid-column-end: 6;
  }
  .lg\:col-span-6 {
    grid-column: span 6/span 6;
  }
  .lg\:col-span-full {
    grid-column: 1/-1;
  }
  .lg\:row-start-6 {
    grid-row-start: 6;
  }
  .lg\:row-end-6 {
    grid-row-end: 6;
  }
  .lg\:row-span-6 {
    grid-row: span 6/span 6;
  }
}
/*
 * component nav "burger" button
 */
/*
 * HTML template example:
 * <button class="burger-button" type="button" role="button" aria-label="open/close navigation"><i></i></button>
 * activate it with a JS toggle-class to .is-active
 * see doc : https: //www.knacss.com/doc.html#buttons
 */
/*
 * burger variables (you can change them)
 */
/*
 * burger button styles
 */
.burger-button {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  outline: 0;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.burger-button > * {
  display: inline-flex;
  vertical-align: top;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: 2.6rem;
  width: 2.6rem;
  padding: 0;
  background-color: transparent;
  background-image: linear-gradient(#333, #333);
  background-position: center;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: 100% 5px;
  transition: 0.25s;
  transition-property: transform, background;
  will-change: transform, background;
}

.burger-button > *::before,
.burger-button > *::after {
  content: "";
  height: 5px;
  background: #333;
  transition: 0.25s;
  transition-property: transform, top;
  will-change: transform, top;
}

.burger-button:hover > * {
  background-color: transparent;
}

.burger-button:focus {
  outline: 0;
}

.burger-button.is-active > * {
  background-image: none;
  justify-content: center;
}

.burger-button.is-active > *::before {
  transform: translateY(50%) rotate3d(0, 0, 1, 45deg);
}

.burger-button.is-active > *::after {
  transform: translateY(-50%) rotate3d(0, 0, 1, -45deg);
}

/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * mic-32-new-rounded-web:
 *   - http://typekit.com/eulas/000000000000000077359c5f
 *   - http://typekit.com/eulas/000000000000000077359c62
 *   - http://typekit.com/eulas/000000000000000077359c8b
 *   - http://typekit.com/eulas/000000000000000077359c83
 * mic-32-new-web:
 *   - http://typekit.com/eulas/000000000000000077359c65
 *   - http://typekit.com/eulas/000000000000000077359c68
 *   - http://typekit.com/eulas/000000000000000077359c86
 *   - http://typekit.com/eulas/000000000000000077359c8d
 *
 * © 2009-2022 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2022-09-26 15:46:48 UTC"}*/
@font-face {
  font-family: "mic-32-new-web";
  src: url("https://use.typekit.net/af/47ed3b/000000000000000077359c65/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/47ed3b/000000000000000077359c65/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/47ed3b/000000000000000077359c65/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: "mic-32-new-web";
  src: url("https://use.typekit.net/af/62d275/000000000000000077359c68/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"), url("https://use.typekit.net/af/62d275/000000000000000077359c68/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"), url("https://use.typekit.net/af/62d275/000000000000000077359c68/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: "mic-32-new-web";
  src: url("https://use.typekit.net/af/5a2eb3/000000000000000077359c86/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/5a2eb3/000000000000000077359c86/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/5a2eb3/000000000000000077359c86/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: "mic-32-new-web";
  src: url("https://use.typekit.net/af/e84ba2/000000000000000077359c8d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/e84ba2/000000000000000077359c8d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/e84ba2/000000000000000077359c8d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: "mic-32-new-rounded-web";
  src: url("https://use.typekit.net/af/cc2574/000000000000000077359c5f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/cc2574/000000000000000077359c5f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/cc2574/000000000000000077359c5f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: "mic-32-new-rounded-web";
  src: url("https://use.typekit.net/af/edb62d/000000000000000077359c62/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"), url("https://use.typekit.net/af/edb62d/000000000000000077359c62/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"), url("https://use.typekit.net/af/edb62d/000000000000000077359c62/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: "mic-32-new-rounded-web";
  src: url("https://use.typekit.net/af/3f9b3b/000000000000000077359c8b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/3f9b3b/000000000000000077359c8b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/3f9b3b/000000000000000077359c8b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
}
@font-face {
  font-family: "mic-32-new-rounded-web";
  src: url("https://use.typekit.net/af/51ee63/000000000000000077359c83/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/51ee63/000000000000000077359c83/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/51ee63/000000000000000077359c83/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
}
.tk-mic-32-new-web {
  font-family: "mic-32-new-web", sans-serif;
}

.tk-mic-32-new-rounded-web {
  font-family: "mic-32-new-rounded-web", sans-serif;
}

@-webkit-keyframes no-anim {}
@-moz-keyframes no-anim {}
@-ms-keyframes no-anim {}
@keyframes no-anim {}
@-webkit-keyframes toBottom {
  0% {
    bottom: 20px;
  }
  50% {
    bottom: 30px;
  }
  100% {
    bottom: 20px;
  }
}
@-moz-keyframes toBottom {
  0% {
    bottom: 20px;
  }
  50% {
    bottom: 30px;
  }
  100% {
    bottom: 20px;
  }
}
@-ms-keyframes toBottom {
  0% {
    bottom: 20px;
  }
  50% {
    bottom: 30px;
  }
  100% {
    bottom: 20px;
  }
}
@keyframes toBottom {
  0% {
    bottom: 20px;
  }
  50% {
    bottom: 30px;
  }
  100% {
    bottom: 20px;
  }
}
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

#top_content {
  scroll-margin-top: 100px;
}

.clear {
  display: block;
  clear: both;
}

.error {
  color: #cc5263;
}

.container {
  margin: 0 auto;
  padding: 1rem;
  max-width: 1200px;
}
.container-fluid {
  margin: 0;
  padding: 1rem;
  width: 100%;
}
.container--featured {
  background-color: #ececec;
  padding: 44px 0;
  margin-bottom: 44px;
}

.centered-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picto {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #ffffff;
}
.picto__mail {
  background: url("/static/core/img/ico-mail.svg");
}
.picto__phone {
  background: url("/static/core/img/ico-phone.svg");
}
.picto__fb {
  background: url("/static/core/img/ico-fb.png");
}
.picto__linkedin {
  background: url("/static/core/img/ico-linkedin.png");
}
.picto--alignLeft {
  float: left;
  margin-right: 1rem;
  margin-top: -10px;
}

.ico__search {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.draftMode {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.draftMode p, .draftMode li, .draftMode a {
  color: #856404;
}

img.align-left {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

img.align-right {
  float: right;
  margin-left: 10px;
  margin-bottom: 10px;
}

@media (max-width: 992px) {
  .centered-box {
    margin-top: 2rem;
  }
}
.header {
  position: relative;
  background-image: url(/static/core/img/bg.jpg);
  background-color: #838383;
  background-repeat: no-repeat;
  background-size: cover;
  height: 420px;
}
.header__logo {
  width: 173px;
  height: 103px;
  margin-bottom: 0;
}
.header__search {
  position: absolute;
  right: 1rem;
  text-align: right;
}
.header__search a {
  color: #cc5263;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
}
.header__title {
  position: absolute;
  bottom: 40px;
}
.header__title span {
  background-color: #cc5263;
  color: #fff;
  font-family: "mic-32-new-web", sans-serif;
  font-size: 1.81rem;
  font-style: italic;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.5;
  padding: 3px 7px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.header__title span:first-letter {
  text-transform: capitalize;
}
.header__title h1 {
  margin-bottom: 0;
}
.header__title h1 > span {
  display: initial;
  width: 100%;
  min-width: 0;
  max-width: 450px;
}
.header__title p {
  margin-bottom: 20px;
}

.home .header {
  height: 554px;
}
.home .header__title {
  position: relative;
  bottom: initial;
}

@media (max-width: 992px) {
  .home .header__title {
    display: flex;
    height: 400px;
  }
  .home .header__title > p {
    align-self: center;
  }
}
@media (min-width: 1200px) {
  .header {
    height: 690px;
  }
  .header__topBar {
    margin-top: 1rem;
  }
  .header__search {
    position: relative;
    right: initial;
    margin-bottom: 4rem;
    padding-top: 2rem;
    text-align: right;
  }
  .header__search .search__button {
    padding-right: 60px;
  }
  .header__title {
    position: absolute;
    bottom: 40px;
  }
  .header__title span {
    padding-right: 2rem;
  }
  .header__title h1 {
    margin-bottom: 0;
  }
  .header__title h1 > span {
    display: inline-block;
  }
  .home .header {
    height: 100vh;
  }
  .home .header__title {
    top: 0;
    transform: translate(0, 50%);
  }
}
.footer {
  padding: 0;
  padding-top: 34px;
  margin-top: 50px;
  background-color: #002845;
}
.footer__logo {
  width: 173px;
  height: 103px;
}
.footer p, .footer a, .footer li {
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
}
.footer a {
  text-decoration: none;
  padding: 12px;
}
.footer ul {
  list-style: none;
  padding-left: 0;
}
.footer__column {
  display: flex;
}
.footer__column:nth-child(2) {
  justify-content: center;
}
.footer__column:nth-child(2) p {
  margin-bottom: 24px;
}
.footer__column:nth-child(2) p strong, .footer__column:nth-child(2) p strong a {
  font-weight: 700;
}
.footer__column:last-child {
  justify-content: flex-end;
  text-align: right;
}
.footer__column:last-child li {
  margin-bottom: 3px;
  padding: 3px;
}
.footer__copyright {
  margin-top: 80px;
}
.footer__copyright p, .footer__copyright a {
  font-size: 0.875rem;
  font-style: italic;
  line-height: 1.2rem;
}
.footer__socialList {
  margin: 0;
}
.footer__socialItem {
  display: inline-block;
  margin-right: 13px;
}
.footer__socialItem:last-child {
  margin-right: 0;
}

@media (max-width: 992px) {
  .footer {
    padding-top: 12px;
  }
  .footer__column {
    justify-self: center;
    margin-bottom: 3rem;
  }
  .footer__column p, .footer__column img, .footer__column ul {
    margin-bottom: 0;
  }
  .footer__column:last-child {
    margin-bottom: 0;
    text-align: left;
  }
  .footer__copyright {
    margin-top: 0;
  }
  .footer__social {
    grid-row: 1;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
}
.home__row {
  margin-bottom: 60px;
}
.home__row p:last-child {
  margin-bottom: 0;
}
.home__sectionTitle, .home p strong, .home .wysiwyg h2 {
  color: #002845;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
}
.home__sectionTitle, .home .wysiwyg h2 {
  font-size: 2.25rem;
  line-height: 2.1rem;
  margin-bottom: 50px;
  margin-top: 28px;
}
.home p strong {
  font-size: 1.5rem;
  line-height: normal;
}
.home .news {
  width: 100%;
}

@media (min-width: 1200px) {
  .home .main {
    margin-top: -250px;
  }
}
.standard {
  padding-top: 50px;
  background-color: #fff;
}

.contact {
  margin-top: 25px;
  background: linear-gradient(90deg, rgb(255, 255, 255) 70%, rgb(0, 40, 69) 72%);
}
.contact .container-fluid {
  padding: 0;
}
.contact .container {
  padding-top: 0;
  padding-bottom: 0;
}
.contact__column {
  padding: 35px 25px;
}
.contact h2 {
  font-size: 2.25rem;
  font-weight: 700;
  margin-top: 0;
}
.contact h3, .contact p {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.25rem;
}
.contact h3 {
  margin-bottom: 50px;
}
.contact__form {
  background-color: #002845;
}
.contact__form h2, .contact__form p, .contact__form li, .contact__form a {
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
}
.contact__form h2, .contact__form p {
  margin-bottom: 25px;
}
.contact__form p, .contact__form a {
  font-size: 1.125rem;
  font-weight: 400;
  letter-spacing: normal;
  line-height: normal;
}
.contact__form p a {
  text-decoration: none;
  font-weight: 700;
}
.contact__legal {
  margin-top: 25px;
}
.contact .captcha-question {
  color: #fff;
  font-size: 1.125rem;
  font-weight: 400;
  padding-right: 1rem;
}

.g-recaptcha {
  display: inline-block;
  float: left;
}

@media (max-width: 992px) {
  .contact {
    background: #fff;
  }
  .contact__form input[type=text] {
    max-width: none;
  }
  .g-recaptcha {
    float: none;
  }
  .contact__submit {
    text-align: center;
  }
}
.article__date {
  color: #002845;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}
.article__categories {
  color: #002845;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
.article__categories a {
  text-decoration: none;
  font-size: 1.125rem;
}
.article__categories a:hover {
  text-decoration: underline;
}
.article__categories--mediatheque {
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
}
.article__title {
  margin-top: 0;
  font-size: 1.375rem;
}
.article__title--mediatheque {
  font-size: 1.375rem;
  margin-bottom: 1.25rem;
}
.article__title--mediatheque:first-letter {
  text-transform: capitalize;
}
.article__image {
  margin-bottom: 50px;
}
.article__image img {
  margin-bottom: 0;
}
.article__content p {
  color: #002845;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.375rem;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
}
.article__content p:last-of-type {
  margin-bottom: 0;
}
.article__controls {
  margin-top: 50px;
}
.article__mediaControls {
  text-align: center;
}
.article__mediaControls .button {
  margin-top: 1rem;
  width: 100%;
}

@media (min-width: 1200px) {
  .article__mediaControls {
    width: calc(100% - 584px);
    align-self: end;
  }
  .article__mediaControls .button {
    margin-top: 0;
    width: 236px;
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  font-weight: normal;
  font-style: normal;
  src: url("fonts/slick.eot");
  src: url("fonts/slick.eot?#iefix") format("embedded-opentype"), url("fonts/slick.woff") format("woff"), url("fonts/slick.ttf") format("truetype"), url("fonts/slick.svg#slick") format("svg");
  font-display: swap;
}
/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir=rtl] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "←";
}

[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir=rtl] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: "→";
}

[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

.button {
  display: inline-block;
  padding: 20px;
  width: 200px;
  background-color: #cc5263;
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
}
.button:hover {
  color: #fff;
}
.button--right {
  float: right;
}
.button--thinFont {
  font-size: 0.75rem;
}
.button--inputArrow {
  height: 40px;
  width: 40px;
  padding: 0;
  background-image: url("/static/core/img/arrow-right.png");
  background-repeat: no-repeat;
  background-position: center center;
}
.button--italic {
  font-size: 1.125rem;
  font-weight: 400;
  font-style: italic;
  line-height: 2.25rem;
  width: 236px;
}
.button--blue {
  max-width: 140px;
  background-color: #002845;
  color: #fff;
  font-weight: 900;
}
.button--submitContact {
  margin-top: 13px;
}

.HighContrastButton:hover {
  cursor: pointer;
}

@media (max-width: 992px) {
  .button--submitContact {
    float: none;
    width: 100%;
  }
}
.topNav {
  display: flex;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 15px 23px;
}
.topNav__list {
  padding: 0;
  margin: 0;
  line-height: 55px;
  position: relative;
}
.topNav__item {
  display: inline-block;
  padding: 0;
  padding-right: 25px;
}
.topNav__item:first-child {
  margin-left: 0;
  padding-left: 0;
}
.topNav__item:first-child:after {
  position: relative;
  top: 13px;
  display: inline-block;
  content: "";
  height: 40px;
  padding: 0;
  margin: 0 0 0 25px;
  border-right: 2px solid #002845;
}
.topNav__item:last-child {
  margin-right: 0;
  padding-right: 0;
}
.topNav__item:last-child:after {
  display: none;
}
.topNav__link {
  color: #002845;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
}
.topNav .button--blue {
  color: #fff;
}

#menu_item_1.mainNav__subMenu:target,
#menu_item_2.mainNav__subMenu:target,
#menu_item_3.mainNav__subMenu:target {
  display: flex;
}

.mainNav {
  display: none;
  width: 75%;
}
.mainNav__list {
  display: flex;
  position: relative;
  justify-content: space-between;
}
.mainNav__item {
  display: inline-flex;
  max-width: 217px;
}
.mainNav__item:hover > .mainNav__subMenu {
  display: flex;
}
.mainNav__link {
  display: inline-block;
  align-self: flex-end;
  text-decoration: none;
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.25rem;
  position: relative;
  padding-bottom: 15px;
  text-align: left;
}
.mainNav__link:hover {
  color: #fff;
  text-decoration: none;
}
.mainNav .mainNav__item:hover .mainNav__link:after,
.mainNav .mainNav__item.selected .mainNav__link:after {
  content: "";
  width: 100%;
  background-color: #fff;
  height: 7px;
  position: absolute;
  bottom: 0px;
  left: 0;
}
.mainNav__subMenu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
}
.mainNav .subMenu {
  width: calc(1200px - 2rem);
  padding: 50px 1.25rem 150px;
  background: #fff;
  margin-top: 15px;
  z-index: 1;
}
.mainNav .subMenu:before {
  content: "";
  height: 15px;
  position: absolute;
  width: 100%;
  top: -15px;
  left: 0;
}
.mainNav .subMenu__item {
  flex: 1;
  width: 100%;
  margin: 0 15px;
}
.mainNav .subMenu__item:first-letter {
  text-transform: uppercase;
}
.mainNav .subMenu__link {
  padding: 0;
  margin: 0 0 15px;
  color: #002845;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.375rem;
  font-weight: 700;
  text-decoration: none;
}
.mainNav .subMenu__subList {
  margin-top: 55px;
}
.mainNav .subMenu__subItem {
  font-weight: normal;
  margin: 0;
  padding: 0;
}
.mainNav .subMenu__subItem:first-letter {
  text-transform: uppercase;
}
.mainNav .subMenu__subLink {
  color: #002845;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 1.875rem;
  text-decoration: none;
}

.mainNav.mainNav--is-opened {
  position: absolute;
  top: 154px;
  left: 0;
  min-height: 100vh;
  width: 100%;
  display: block;
  background-color: #fff;
  z-index: 999;
  padding: 25px;
}
.mainNav.mainNav--is-opened .mainNav__list, .mainNav.mainNav--is-opened .mainNav__item, .mainNav.mainNav--is-opened .mainNav__subMenu {
  position: relative;
  display: block;
  width: 100%;
  max-width: none;
  padding: 0;
  margin: 0;
}
.mainNav.mainNav--is-opened .mainNav__item {
  margin-top: 40px;
}
.mainNav.mainNav--is-opened .mainNav__item:first-child {
  margin-top: 0;
}
.mainNav.mainNav--is-opened .mainNav__link {
  margin: 0;
  padding: 0;
  color: #002845;
}
.mainNav.mainNav--is-opened .mainNav .subMenu {
  margin: 0;
  width: 100%;
}
.mainNav.mainNav--is-opened .mainNav .subMenu:before {
  content: none;
}
.mainNav.mainNav--is-opened .subMenu {
  margin-top: 34px;
}
.mainNav.mainNav--is-opened .subMenu__subList {
  margin: 38px 0;
}
.mainNav.mainNav--is-opened .subMenu__item {
  flex: none;
  margin: 0;
  padding: 0;
}
.mainNav.mainNav--is-opened .subMenu__link {
  font-family: "mic-32-new-web", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: italic;
}
.mainNav.mainNav--is-opened .subMenu__subLink {
  font-family: "mic-32-new-web", sans-serif;
  font-size: 1.31rem;
  font-weight: 500;
  font-style: italic;
}
.mainNav.mainNav--is-opened .subMenu__subItem {
  padding-left: 27px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.mainNav.mainNav--is-opened .subMenu__subItem:first-child {
  padding-top: 0;
}

.mainNav .topNavMobile {
  display: none;
  visibility: hidden;
}

.mainNav.mainNav--is-opened .topNavMobile {
  display: block;
  visibility: visible;
  margin-top: 40px;
  margin-bottom: 120px;
  padding-top: 42px;
  border-top: 3px solid #002845;
}
.mainNav.mainNav--is-opened .topNavMobile__item {
  margin-top: 40px;
}
.mainNav.mainNav--is-opened .topNavMobile__item:first-child {
  margin-top: 0;
}
.mainNav.mainNav--is-opened .topNavMobile__link {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
}

@media (min-width: 992px) {
  .mainNav {
    display: block;
    float: right;
    border-top: 0;
    margin-top: 35px;
  }
  .subMenu {
    width: calc(992px - 2rem);
  }
}
@media (min-width: 1200px) {
  .subMenu {
    width: calc(1200px - 2rem);
  }
}
.burger-button {
  margin-top: 62px;
}
.burger-button > :after, .burger-button > :before {
  background: #fff;
}
.burger-button > * {
  background-image: linear-gradient(#fff, #fff);
}

.annuaire {
  margin-bottom: 0;
  position: relative;
  padding: 0;
}
.annuaire__column:first-child {
  position: absolute;
  display: inline-block;
  bottom: 94px;
  padding-right: 4rem;
  width: 60%;
  float: right;
}
.annuaire__column:nth-child(2) {
  width: 40%;
  float: right;
  padding-left: 55px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #002845;
  position: relative;
  max-height: 620px;
}
.annuaire__text p {
  color: #002845;
  font-family: "mic-32-new-web", sans-serif;
  font-size: 1.875rem;
  font-weight: 400;
  font-style: italic;
  line-height: 44px;
  text-align: right;
  margin-bottom: 0;
}
.annuaire__formTitle {
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
  font-size: 2.25rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 38px;
}
.annuaire__list {
  margin-top: 38px;
  margin-bottom: 0;
  margin-left: 0;
  padding-left: 0;
}
.annuaire__list ul {
  margin-bottom: 0;
  padding-left: 0;
}
.annuaire__listItem, .annuaire__list li {
  width: 100%;
  max-width: 339px;
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 600;
  font-style: italic;
  list-style: none;
  padding: 34px 0;
  border-top: 1px solid #fff;
}
.annuaire__listItem strong, .annuaire__list li strong {
  font-weight: 700;
  color: #fff;
  font-size: 1.125rem;
}
.annuaire__listItem a, .annuaire__list li a {
  font-weight: 600;
  color: #fff;
  font-size: 1.125rem;
  text-decoration: none;
}
.annuaire__listItem:last-child, .annuaire__list li:last-child {
  border-bottom: 1px solid #fff;
}
.annuaireResult {
  padding-top: 50px;
}
.annuaireResult [aria-controls] {
  cursor: default !important;
}
.annuaireResult__searchBox {
  padding: 1.4rem 0.7rem;
  background-color: #002845;
}
.annuaireResult__searchBox .searchbar-input-wrapper input[type=search] {
  max-width: 100%;
  width: 320px;
  margin-right: 0px;
}
.annuaireResult__searchBox .button {
  margin-left: -4px;
}
.annuaireResult__searchBoxTitle {
  margin: 0 0 0.625rem;
  color: #fff;
  line-height: normal;
}
.annuaireResult__aside {
  padding-right: 34px;
}
.annuaireResult__aside p {
  font-family: "Source Sans Pro", sans-serif;
  color: #002845;
  font-size: 1.875rem;
  line-height: normal;
  font-weight: 700;
}
.annuaireResult .ui-tabs-tab {
  list-style: none;
}
.annuaireResult__tab {
  padding: 20px 0;
  margin-bottom: 15px;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  font-family: "mic-32-new-web", sans-serif;
  color: #002845;
  background-color: #e6e6e6;
  display: block;
  text-decoration: none;
  position: relative;
  line-height: 40px;
}
.annuaireResult__tab:after {
  display: none;
  position: absolute;
  content: "";
  background-image: url(/static/core/img/arrow-right-black.png);
  background-position: 50%;
  background-repeat: no-repeat;
  height: 40px;
  padding: 0;
  width: 40px;
  right: 0;
}
.annuaireResult__tab--active {
  background-image: url(/static/core/img/arrow-right.png);
  color: #fff;
  background-color: #cc5263;
  margin-bottom: 0;
}
.annuaireResult__tab--active:hover {
  color: #fff;
}
.annuaireResult__tab:hover {
  color: #fff;
  background-color: #cc5263;
  text-decoration: none;
  cursor: pointer;
}
.annuaireResult__row {
  border-bottom: 1px solid #ececec;
  padding-top: 30px;
  padding-bottom: 17px;
}
.annuaireResult__row:last-of-type {
  margin-bottom: 30px;
}
.annuaireResult__row h3, .annuaireResult__row h4, .annuaireResult__row p, .annuaireResult__row a {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  color: #002845;
  font-size: 1.375rem;
  line-height: 2.25rem;
}
.annuaireResult__row h3, .annuaireResult__row h3 a {
  font-weight: 700;
}
.annuaireResult__row h4 {
  font-weight: 600;
}
.annuaireResult__row p {
  font-weight: 600;
}
.annuaireResult__row a {
  text-decoration: none;
}

.ui-tabs-nav {
  padding-left: 0;
}

.ui-tabs-active > a {
  color: #fff;
  background-color: #cc5263;
  text-decoration: none;
}
.ui-tabs-active > a:after {
  transform: rotate(90deg);
  background-image: url(/static/core/img/arrow-right.png);
  background-repeat: no-repeat;
  background-position: 50%;
}

.ui-tabs-tab > a:hover:after {
  transform: rotate(90deg);
  background-image: url(/static/core/img/arrow-right.png);
  background-repeat: no-repeat;
  background-position: 50%;
}

@media (max-width: 992px) {
  .annuaire__column:first-child {
    display: none;
  }
  .annuaire__column:nth-child(2) {
    width: 100%;
    display: block;
  }
  .annuaireResult__searchBox .searchbar-input-wrapper {
    width: calc(100% - 40px);
  }
  .annuaireResult__searchBox .searchbar-input-wrapper input[type=search] {
    display: inline-block;
    max-width: 320px;
  }
  .annuaireResultResult__aside {
    padding-left: 1rem;
  }
}
@media (min-width: 1200px) {
  .annuaire {
    padding: 1rem 0;
    margin-bottom: 84px;
  }
}
.slider {
  overflow: hidden;
  width: 100%;
  max-width: 900px;
}
.slider__item {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 900px;
  height: 515px;
}
.slider .captions {
  margin-top: -60px;
}
.slider__caption p {
  bottom: 0;
  width: 80%;
  max-width: 715px;
  height: 60px;
  line-height: 60px;
  padding-right: 37px;
  margin-bottom: 0;
  background-color: #cc5263;
  color: #ffffff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  font-style: normal;
  text-align: right;
  overflow: hidden;
  float: right;
  position: relative;
  display: block;
}

.slick-prev, .slick-next {
  z-index: 99 !important;
}

.slick-prev {
  left: 10px !important;
}

.slick-next {
  right: 10px !important;
}

#toc-1 {
  margin-top: -30px;
}

.toc {
  display: block;
  grid-area: toc;
}
.toc ul {
  list-style: none;
  padding-left: 40px;
  margin-left: 10px;
  border-left: 4px solid #002845;
}
.toc li, .toc li a {
  color: #002845;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.375rem;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 36px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.toc-visible a {
  margin-left: -16px;
}
.toc-visible:before {
  content: " ";
  width: 17px;
  height: 17px;
  position: relative;
  display: inline-block;
  background-color: #cc5263;
  left: -50px;
  top: 2px;
  border-radius: 10px;
}

@media (min-width: 1200px) {
  .toc {
    --offset: 680px;
    --max-height: calc(100vh - var(--offset));
    top: 20px;
    position: -webkit-sticky;
    position: sticky;
    overflow: auto;
    max-height: var(--max-height);
  }
  .toc li, .toc li a {
    max-width: 285px;
  }
}
h1:first-letter, h2:first-letter, h3:first-letter, h4:first-letter {
  text-transform: capitalize;
}

h2 {
  color: #002845;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.875rem;
  font-weight: 700;
  margin-top: 50px;
  line-height: normal;
}

h3 {
  color: #002845;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
}

p, li, a {
  color: #002845;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.375rem;
  font-weight: 400;
  line-height: normal;
}

p strong {
  font-weight: 700;
}

p a {
  font-weight: 400;
  text-decoration: underline;
  font-size: 1.375rem;
  line-height: normal;
}

h2, h3, p, img, ul {
  margin-bottom: 40px;
}

p:has(img) img, p:has(> img) img {
  margin-bottom: 0;
}

article p {
  line-height: 2.25rem;
}
article li {
  line-height: 2.25rem;
}
article li strong {
  font-weight: 600;
}

.thumbnail__item {
  display: inline-block;
  width: 100%;
  max-width: 380px;
  height: auto;
  background-color: #fff;
  justify-self: center;
}
.thumbnail__image {
  position: relative;
  width: 100%;
  height: 225px;
  display: block;
  overflow: hidden;
}
.thumbnail__date {
  background-color: #cc5263;
  color: #fff;
  display: inline-block;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  width: 50%;
  text-align: right;
  padding-right: 20px;
  padding-top: 13px;
  padding-bottom: 13px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.thumbnail__caption {
  background-color: #fff;
  padding: 13px 15px 13px 0;
}
.thumbnail__categoriesItem {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
}
.thumbnail__title, .thumbnail__title a {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.375rem;
  font-weight: 700;
  text-decoration: none;
  line-height: normal;
  margin-bottom: 30px;
}
.thumbnailMedia {
  margin-top: 50px;
}
.thumbnailMedia__item {
  display: flex;
  height: auto;
  margin-bottom: 1rem;
  align-items: end;
}
.thumbnailMedia__image {
  height: auto;
}
.thumbnailMedia__image img {
  margin-bottom: 0;
}

.home .thumbnail__item {
  margin-bottom: 40px;
}
.home .thumbnail__caption {
  padding: 0;
  padding-top: 15px;
}
.home .thumbnail__caption h3 {
  margin-bottom: 0;
}

@media (min-width: 1200px) {
  .thumbnail__categories {
    margin-bottom: 22px;
  }
}
input[type=text], input[type=search] {
  background-color: #fff;
  height: 40px;
  margin: 12px 10px 12px 0;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  font-style: italic;
  color: #868686;
  padding-left: 20px;
  max-width: 285px;
}
input[type=text].input--longWidth, input[type=search].input--longWidth {
  max-width: 355px;
}

input[type=search]:hover {
  cursor: text;
}

textarea {
  margin-top: 12px;
  background-color: #fff;
  width: 100%;
  height: 350px;
  padding: 10px 20px;
}

textarea::placeholder, input::placeholder {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  font-style: italic;
  color: #868686;
}

@media (max-width: 992px) {
  input[type=text].input--longWidth, input[type=search].input--longWidth {
    max-width: none;
    width: 100%;
  }
}
@media (min-width: 992px) {
  input[type=text], input[type=search] {
    width: 285px;
  }
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown__label {
  color: #002845;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.375rem;
  font-weight: 700;
  font-style: normal;
  text-align: right;
  margin-right: 20px;
}
.dropdown__init {
  font-size: 1.125rem;
  display: inline-block;
  width: 144px;
  background-color: #cc5263;
  text-align: left;
  font-style: italic;
  color: #fff;
  padding: 20px;
}
.dropdown__init:after {
  content: url("/static/core/img/arrow-down.png");
  width: 18px;
  height: 28px;
  right: 20px;
  text-align: center;
  position: absolute;
}
.dropdown__init:hover {
  cursor: pointer;
}
.dropdown__init:hover .dropdown__content {
  display: block;
}
.dropdown__content {
  display: none;
  position: absolute;
  z-index: 1;
  list-style: none;
  width: 100%;
  padding: 0;
}
.dropdown__link {
  display: inline-block;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  color: #4c4c4c;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  text-align: left;
}
.dropdown__link:hover {
  background: #ececec;
  text-decoration: none;
}

.dropdown:hover .dropdown__content {
  display: block;
}

@media (min-width: 1200px) {
  .dropdown__init {
    width: 236px;
  }
}
.searchbar-input-wrapper {
  display: inline-block;
}

.searchbar-autocomplete-results {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  position: absolute;
  z-index: 990;
  display: block;
  overflow-y: auto;
  width: auto;
  min-width: 300px;
  max-height: 50vh;
  padding: 0.375rem 0.75rem;
  color: #4c4c4c;
  background-color: #fff;
  text-align: left;
}

.searchbar-autocomplete-results .suggestion-choice {
  padding: 0.25em;
  cursor: pointer;
}

.searchbar-autocomplete-results .suggestion-choice:hover {
  background: rgba(236, 236, 236, 0.5);
}

.searchbar-autocomplete-results li::marker {
  display: none;
}

section.search h2:first-of-type {
  margin-top: 30px;
  font-size: 2.25rem;
  font-weight: 700;
}
section.search h2:first-of-type em {
  font-weight: 600;
}

.searchForm {
  position: relative;
  z-index: 99;
}

.searchResult {
  list-style: none;
  padding-left: 0;
}
.searchResult li {
  padding-bottom: 45px;
  margin-bottom: 45px;
  border-bottom: 1px solid #002845;
}
.searchResult li:last-child {
  border-bottom: 0;
}
.searchResult__title {
  margin-bottom: 1.25rem;
  line-height: normal;
}
.searchResult__title a {
  color: #002845;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.375rem;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
}
.searchResult__title:first-letter {
  text-transform: uppercase;
}
.searchResult__paragraph {
  color: #002845;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.375rem;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 1.25rem;
}
.searchResult .highlighted {
  font-weight: 700;
}
.searchResult__link {
  font-weight: 600;
  line-height: 36px;
}
.searchResult__link:before {
  content: "·";
  width: 2px;
  height: 2px;
  display: inline-block;
  margin-right: 34px;
}

@media (min-width: 1200px) {
  .searchForm {
    position: absolute;
    right: 0;
  }
}
.pagination {
  text-align: center;
  margin-top: 10px;
}
.pagination__list {
  list-style: none;
}
.pagination__item {
  display: inline-block;
  margin-right: 1rem;
}
.pagination__item:last-child {
  margin-right: 0;
}
.pagination__link {
  text-decoration: none;
  color: #4c4c4c;
  font-weight: 600;
  font-size: 1.125rem;
}
.pagination__link:hover {
  text-decoration: none;
}
.pagination__page .pagination__link {
  display: inline-block;
  background-color: #ececec;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.pagination__page--active .pagination__link {
  background-color: #cc5263;
  color: #fff;
}

.smart-popin {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: 9999;
}
.smart-popin:target {
  opacity: 1;
  visibility: visible;
}
.smart-popin .sp-table {
  display: table;
  height: 100%;
  width: 100%;
}
.smart-popin .sp-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 10px;
}
.smart-popin .sp-body {
  position: relative;
  z-index: 1;
  width: auto;
  max-width: 900px;
  margin: 90px auto;
}
.smart-popin .sp-body * {
  max-width: 100%;
}
.smart-popin .sp-back { /* only used to get clicks out of the popin body */
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
}
.smart-popin .sp-close {
  position: absolute;
  top: -70px;
  right: 0;
  width: 56px;
  height: 56px;
  text-align: center;
  line-height: 56px;
  font-size: 3rem;
  background-color: #fff;
  font-weight: 900;
  text-decoration: none;
  border-radius: 28px;
}

.urgenceNumber {
  padding: 2.5rem 0.75rem 1.875rem 1.45rem;
}
.urgenceNumber__title {
  font-size: 4.5rem;
  color: #fff;
  font-weight: 600;
  font-family: "Source Sans Pro", sans-serif;
  text-transform: uppercase;
  line-height: 4rem;
  margin-bottom: 0.375rem;
}
.urgenceNumber__title em {
  color: #fff;
  margin-left: 2.625rem;
  font-size: 6.25rem;
  font-family: "mic-32-new-rounded-web", sans-serif;
  font-style: normal;
  font-weight: 700;
}
.urgenceNumber__subTitle {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
}
.urgenceNumber__text {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: normal;
}
.urgenceNumber__text:last-child {
  margin-bottom: 0;
}
.urgenceNumber:nth-child(1) {
  background-color: #e8303a;
}
.urgenceNumber:nth-child(1) .urgenceNumber__text, .urgenceNumber:nth-child(1) .urgenceNumber__subTitle {
  color: #fff;
}
.urgenceNumber:nth-child(2) {
  background-color: #2c73ab;
}
.urgenceNumber:nth-child(3) {
  background-color: #2b2b2b;
}
.urgenceNumber:nth-child(3) .urgenceNumber__title {
  font-size: 3.75rem;
  line-height: 3.1rem;
}
.urgenceNumber:nth-child(3) .urgenceNumber__title em {
  padding-bottom: 1.5rem;
  display: inline-block;
}
.urgenceNumber:nth-child(4) {
  background-color: #ffa32a;
}
.urgenceNumber:nth-child(4) .urgenceNumber__subTitle {
  font-size: 1.875rem;
  line-height: 1.75rem;
}
.urgenceNumber:nth-child(4) .urgenceNumber__text {
  font-size: 1rem;
}
.urgenceNumber:nth-child(5) {
  background-color: #2cbbf3;
}
.urgenceNumber:nth-child(5) .urgenceNumber__subTitle {
  font-size: 1.875rem;
  line-height: 1.75rem;
}
.urgenceNumber:nth-child(6) {
  background-color: #745a9b;
}
.urgenceNumber:nth-child(6) .urgenceNumber__subTitle {
  font-size: 1.875rem;
  line-height: 1.75rem;
}
.urgenceNumber:nth-child(7) {
  background-color: #f14249;
}
.urgenceNumber:nth-child(7) .urgenceNumber__subTitle {
  font-size: 3rem;
  line-height: 1.75rem;
}
.urgenceNumber:nth-child(7) .urgenceNumber__subTitle strong {
  font-size: 4.5rem;
  line-height: 1.75rem;
  color: #fff;
}
.urgenceNumber:nth-child(8) {
  background-color: #304b5e;
}
.urgenceNumber:nth-child(8) .urgenceNumber__title {
  font-size: 2.75rem;
  line-height: 2.7rem;
}
.urgenceNumber:nth-child(8) .urgenceNumber__subTitle {
  font-size: 2.31rem;
  line-height: 2.3rem;
}
.urgenceNumber:nth-child(8) .urgenceNumber__text {
  margin-top: 20px;
  margin-left: 1.875rem;
  padding-right: 1rem;
  font-size: 0.9rem;
  text-transform: uppercase;
}
.urgenceNumber:nth-child(8) .urgenceNumber__text strong {
  color: #fff;
  font-size: 0.9rem;
  letter-spacing: normal;
}

.accessibility .header__search a {
  color: #002845 !important;
}
.accessibility .mainNav__link {
  color: #002845 !important;
}
.accessibility .slider__caption p {
  background-color: #002845 !important;
}
.accessibility .annuaireResult__tab--active {
  background-color: #002845 !important;
}
.accessibility .annuaireResult__tab:hover {
  background-color: #002845 !important;
}
.accessibility .ui-tabs-active > a {
  background-color: #002845 !important;
}
.accessibility .button {
  background-color: #002845 !important;
}
.accessibility .pagination__page--active .pagination__link {
  background-color: #002845 !important;
}
.accessibility .dropdown__init {
  background-color: #002845 !important;
}
.accessibility .slider__caption p {
  background-color: #002845 !important;
}
.accessibility .thumbnail__date {
  background-color: #002845 !important;
}
.accessibility .toc-visible:before {
  background-color: #002845 !important;
}
.accessibility .header__search a {
  color: #002845 !important;
}
.accessibility .header__title span {
  background-color: #002845 !important;
}
.accessibility .urgenceNumber {
  background-color: #002845 !important;
  border: 2px solid #fff;
}
.accessibility .button--inputArrow {
  background-color: #fff !important;
  background-image: url("/static/core/img/arrow-right-blue.png") !important;
  margin-left: 1px;
}
.accessibility .cls-1 {
  fill: #002845 !important;
}