@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@include keyframes(no-anim) {

}


@mixin animation ($stuff ) {
    -webkit-animation: $stuff;
    -moz-animation: $stuff;
    -ms-animation: $stuff;
    animation: $stuff;
}

@include keyframes(toBottom) {
  0% {
   bottom: 20px;
  }
  50% {
    bottom: 30px;
  }
  100% {
    bottom: 20px;
  }
}
