.accessibility {
  .header {
    &__search {
      a {
        color: $highContrast !important;
      }
    }
  }
  .mainNav__link {
    color: $highContrast !important;
  }
  .slider {
    &__caption p {
      background-color: $highContrast !important;
    }
  }

  .annuaire {
    &Result {
      &__tab {
        &--active {
          background-color: $highContrast !important;
        }
        &:hover {
          background-color: $highContrast !important;
        }
      }
    }
  }
  .ui-tabs-active > a {
    background-color: $highContrast !important;
  }

  .button {
    background-color: $highContrast !important;
  }

  .pagination {
      &__page--active .pagination__link {
      background-color: $highContrast !important;
    }
  }

  .dropdown {
    &__init {
      background-color: $highContrast !important;
    }
  }

  .slider {
    &__caption p {
      background-color: $highContrast !important;
    }
  }

  .thumbnail {
      &__date {
        background-color: $highContrast !important;
      }
  }

  .toc {
    &-visible {
      &:before {
        background-color: $highContrast !important;
      }
    }
  }

  .header {
    &__search {
      a {
        color: $highContrast !important;
      }
    }
    &__title {
      span {
        background-color: $highContrast !important;
      }
    }
  }

  .urgenceNumber {
    background-color: $highContrast !important;
    border: 2px solid $color-white;
  }

  .button--inputArrow {
    background-color: $color-white !important;
    background-image: url('/static/core/img/arrow-right-blue.png')!important;
    margin-left: 1px;
  }
  .cls-1 {
    fill: $color-dark-blue !important;
  }
}