// TOC : Jquery module Automatique Table of content
// https://github.com/jgallen23/toc
#toc-1 {
  margin-top: -30px
}

.toc {
  display: block;
  grid-area: toc;
  ul {
    list-style: none;
    padding-left: 40px;
    margin-left: 10px;
    border-left: 4px solid $color-dark-blue;
  }
  li, li a {
    color: $color-dark-blue;
    font-family: $primary-font;
    font-size: 1.375rem;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: 36px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &-visible {
    a {
      margin-left: -16px;
    }

    &:before {
      content: " ";
      width: 17px;
      height: 17px;
      position: relative;
      display: inline-block;
      background-color: $color-red;
      left: -50px;
      top: 2px;
      border-radius: 10px;
    }
  }
}

@media (min-width: $large) {
  .toc {
    --offset: 680px;
    --max-height: calc(100vh - var(--offset));
    top: 20px;
    position: -webkit-sticky;
    position: sticky;
    overflow: auto;
    max-height: var(--max-height);
    li, li a {
      max-width: 285px;
    }
  }

}