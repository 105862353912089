/* -------------------------------------------------- */
/* ==Grillade : système de micro-grille  de KNACSS   */
/* ------------------------------------------------ */
// Grillade est directement inspiré tailwindcss.com et de ses classes utilitaires

// Les variables ci-dessous ne sont appliquées que si elles n'ont pas été renseignées
// dans le fichiers de variables de KNACSS.
//@import "../abstracts/variables-sass";

@if variable_exists(grid-columns) {
  $grid-columns: $grid-columns !global;
} @else {
  $grid-columns: 6 !global;
}

@if variable_exists(gaps) {
  $gaps: $gaps !global;
} @else {
  $gaps: (
    "0": 0,
    "5": 0.313rem,
    "10": 0.625rem,
    "16": 1rem,
    "20": 1.25rem,
    "36": 2.25rem,
  ) !global;
}

@if variable_exists(breakpoints) {
  $breakpoints: $breakpoints !global;
} @else {
  $breakpoints: (
    sm: 576px,
    md: 992px,
    lg: 1330px,
  ) !global;
}

.grid {
  display: grid;
}
// grid class for each breakpoint
@each $bp, $bpv in $breakpoints {
  @media (min-width: #{$bpv}) {
    .#{$bp}\:grid {
      display: grid;
    }
  }
}

/* grid-template-columns initialisation */
@for $i from 1 through $grid-columns {
  .grid-cols-#{$i} {
    grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
  }
}

/* grid-template-columns au sein des breakpoints */
@each $bp, $bpv in $breakpoints {
  @media (min-width: #{$bpv}) {
    @for $i from 1 through $grid-columns {
      .#{$bp}\:grid-cols-#{$i} {
        grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
      }
    }
  }
}

/* gap initialisation */
@each $key, $value in $gaps {
  .gap-#{$key} {
    gap: $value;
  }
  .gap-x-#{$key} {
    column-gap: $value;
  }
  .gap-y-#{$key} {
    row-gap: $value;
  }
}

/* gap au sein des breakpoints */
@each $bp, $bpv in $breakpoints {
  @media (min-width: #{$bpv}) {
    @each $key, $value in $gaps {
      .#{$bp}\:gap-#{$key} {
        gap: $value;
      }
      .#{$bp}\:gap-x-#{$key} {
        column-gap: $value;
      }
      .#{$bp}\:gap-y-#{$key} {
        row-gap: $value;
      }
    }
  }
}

/* grid-items initialisation */
@for $i from 1 through $grid-columns {
  .col-start-#{$i} {
    grid-column-start: #{$i};
  }
  .col-end-#{$i} {
    grid-column-end: #{$i};
  }
  .col-span-#{$i} {
    grid-column: span #{$i} / span #{$i};
  }
  .col-span-full {
    grid-column: 1 / -1;
  }
  .row-start-#{$i} {
    grid-row-start: #{$i};
  }
  .row-end-#{$i} {
    grid-row-end: #{$i};
  }
  .row-span-#{$i} {
    grid-row: span #{$i} / span #{$i};
  }
}

/* grid-items au sein des breakpoints */
@each $bp, $bpv in $breakpoints {
  @media (min-width: #{$bpv}) {
    @for $i from 1 through $grid-columns {
      // loop for each breakpoint

      .#{$bp}\:col-start-#{$i} {
        grid-column-start: #{$i};
      }
      .#{$bp}\:col-end-#{$i} {
        grid-column-end: #{$i};
      }
      .#{$bp}\:col-span-#{$i} {
        grid-column: span #{$i} / span #{$i};
      }
      .#{$bp}\:col-span-full {
        grid-column: 1 / -1;
      }
      .#{$bp}\:row-start-#{$i} {
        grid-row-start: #{$i};
      }
      .#{$bp}\:row-end-#{$i} {
        grid-row-end: #{$i};
      }
      .#{$bp}\:row-span-#{$i} {
        grid-row: span #{$i} / span #{$i};
      }
    }
  }
}
