.contact {
  margin-top: 25px;
  background: linear-gradient(90deg, rgba(255,255,255,1) 70%, rgba(0,40,69,1) 72%);

  .container-fluid {
    padding: 0;
  }
  .container {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__column {
    padding: 35px 25px;
  }

  h2 {
    font-size: 2.25rem;
    font-weight: 700;
    margin-top: 0;
  }
  h3, p {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.25rem;
  }
  h3 {
    margin-bottom: 50px;
  }

  &__form {
    background-color: $color-dark-blue;
    h2, p, li, a {
      font-family: $primary-font;
      color: $color-white;
    }
    h2, p {
      margin-bottom: 25px;
    }
    p, a {
      font-size: 1.125rem;
      font-weight: 400;
      letter-spacing: normal;
      line-height: normal;
    }
    p a {
      text-decoration: none;
      font-weight: 700;
    }
  }
  &__legal {
    margin-top: 25px;
  }
  .captcha-question {
    color: $color-white;
    font-size: 1.125rem;
    font-weight: 400;
    padding-right: 1rem;
  }
}

.g-recaptcha {
  display: inline-block;
  float: left;
}

@media (max-width: $medium) {
  .contact {
    background: $color-white;
  }
  .contact__form input[type=text] {
    max-width: none;
  }

  .g-recaptcha {
    float: none;
  }
  .contact__submit {
    text-align: center;
  }
}