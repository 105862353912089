html{scroll-behavior:smooth}

body {
  font-family: $primary-font;
  font-weight: 400;
  font-size: 1rem;
}

// scroll to content
#top_content {
  scroll-margin-top: 100px;
}

.clear {
  display: block;
  clear: both;
}

.error {
  color: $color-red;
}

.container {
  margin: 0 auto;
  padding: 1rem;
  max-width: $large;
  &-fluid {
    margin: 0;
    padding: 1rem;
    width: 100%;
  }
  &--featured {
    background-color: $color-grey;
    padding: 44px 0;
    margin-bottom: 44px;
  }
}

.centered-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picto {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #ffffff;
  &__mail {
    background: url("/static/core/img/ico-mail.svg");
  }
  &__phone {
    background: url("/static/core/img/ico-phone.svg");
  }
  &__fb {
    background: url("/static/core/img/ico-fb.png");
  }
  &__linkedin {
    background: url("/static/core/img/ico-linkedin.png");
  }

  &--alignLeft {
    float: left;
    margin-right: 1rem;
    margin-top: -10px;
  }
}

.ico__search {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.draftMode {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  p, li, a {
    color: #856404;
  }
}

img.align-left {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}
img.align-right {
  float: right;
  margin-left: 10px;
  margin-bottom: 10px;
}

@media (max-width: $medium) {
  .centered-box {
    margin-top: 2rem;
  }
}
