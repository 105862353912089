.pagination {
  text-align: center;
  margin-top: 10px;
  &__list {
    list-style: none;
  }
  &__item {
    display: inline-block;
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
  &__link {
    text-decoration: none;
    color: $color-grey2;
    font-weight: 600;
    font-size: 1.125rem;
    &:hover {
      text-decoration: none;
    }
  }
  &__page .pagination__link {
    display: inline-block;
    background-color: $color-grey;
    border-radius:20px;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  &__page--active .pagination__link {
    background-color: $color-red;
    color: $color-white;
  }


}