.button {
  display: inline-block;
  padding: 20px;
  width: 200px;
  background-color: $color-red;
  color: $color-white;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $color-white;
  }
  &--right {
    float: right;
  }
  &--thinFont {
    font-size: .75rem;
  }

  &--inputArrow {
    height: 40px;
    width: 40px;
    padding: 0;
    background-image: url("/static/core/img/arrow-right.png");
    background-repeat: no-repeat;
    background-position: center center;
  }

  &--italic {
    font-size: 1.125rem;
    font-weight: 400;
    font-style: italic;
    line-height: 2.25rem;
    width: 236px;
  }

  &--blue {
    max-width: 140px;
    background-color: $color-dark-blue;
    color: $color-white;
    font-weight: 900;
  }

  &--submitContact {
    margin-top: 13px;
  }
}

.HighContrastButton:hover {
  cursor: pointer;
}

@media (max-width: $medium) {
  .button--submitContact {
    float: none;
    width: 100%;
  }
}
