.home {
  .main {}

  &__row {
    margin-bottom: 60px;
    & p:last-child {
      margin-bottom: 0;
    }
  }

  &__sectionTitle, p strong, .wysiwyg h2 {
    color: $color-dark-blue;
    font-family: $primary-font;
    font-weight: 700;
  }
  &__sectionTitle, .wysiwyg h2 {
    font-size: 2.25rem;
    line-height: 2.1rem;
    margin-bottom: 50px;
    margin-top: 28px;

  }
  p strong {
    font-size: 1.5rem;
    line-height: normal;
  }

  .news {
    width: 100%;
  }
}

@media (min-width: $large) {
  .home {
    .main {
      margin-top: -250px;
    }
  }
}


