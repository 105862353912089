.article {
  &__date {
    color: $color-dark-blue;
    font-family: $primary-font;
    font-size: 1.125rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
  }
  &__categories {
    color: $color-dark-blue;
    font-family: $primary-font;
    font-size: 1.125rem;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    a {
      text-decoration: none;
      font-size: 1.125rem;
      &:hover {
        text-decoration: underline;
      }
    }
    &--mediatheque {
      font-size: 1.125rem;
      margin-bottom: 1.25rem;
    }
  }
  &__title {
    margin-top: 0;
    font-size: 1.375rem;
    &--mediatheque {
      font-size: 1.375rem;
      margin-bottom: 1.25rem;
      &:first-letter {
        text-transform: capitalize;
      }
    }
  }
  &__image {
    margin-bottom: 50px;
    img {
      margin-bottom: 0;
    }
  }
  &__content p {
    color: $color-dark-blue;
    font-family: $primary-font;
    font-size: 1.375rem;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__controls {
    margin-top: 50px;
  }
  &__mediaControls {
    text-align: center;

    .button {
      margin-top: 1rem;
      width: 100%;
    }
  }
}

@media (min-width: $large) {
  .article {
    &__mediaControls {
      width: calc(100% - 584px);
      //text-align: left;
      //display: inline-block;
      align-self: end;
      //padding-left: 90px;
      .button {
        margin-top: 0;
        width: 236px;
      }
    }
  }
}