// Affichage des textes provenant de l'editeur wyswyg
h1, h2, h3, h4 {
  &:first-letter {
    text-transform: capitalize;
  }
}

h2 {
    color: $color-dark-blue;
    font-family: $primary-font;
    font-size: 1.875rem;
    font-weight: 700;
    margin-top: 50px;
    line-height: normal;
}

h3 {
    color: $color-dark-blue;
    font-family: $primary-font;
    font-size: 1.5rem;
    font-weight: 700;
    font-style: normal;
    line-height: normal;
    //line-height: 2.25rem;
}

p, li, a {
    color: $color-dark-blue;
    font-family: $primary-font;
    font-size: 1.375rem;
    font-weight: 400;
    line-height: normal;
}

p strong {
    font-weight: 700;
}

p a {
    font-weight: 400;
    text-decoration: underline;
    font-size: 1.375rem;
    line-height: normal;
}

h2, h3, p, img, ul {
    margin-bottom: 40px;
}

p:has(img) img, p:has(> img) img {
    margin-bottom: 0;
}


article {
    p {
        line-height: 2.25rem;
    }
    li {
        line-height: 2.25rem;
        strong {
            font-weight: 600;
        }
    }
}