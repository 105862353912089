/*!
* CH Cognac
* @author: ssorin - <ssorin@dwdlc.com>
* v1.0 2022/06
*/

@import "knacss/knacss";

// Settings
@import "settings/_colors";
@import "settings/_fonts";
@import "settings/_animation";

// Layout
@import "layout/_body";
@import "layout/_header";
@import "layout/_footer";

// Pages
@import "pages/_home";
@import "pages/_standard";
@import "pages/_contact";
@import "pages/_actualites";

// slick slider
@import "slick/_slick";
@import "slick/_slick-theme";

// Composants
@import "composants/button";
@import "composants/top-nav";
@import "composants/main-nav";
@import "composants/burger_button";
@import "composants/annuaire";
@import "composants/slider";
@import "composants/toc";
@import "composants/text";
@import "composants/thumbail";
@import "composants/input";
@import "composants/select-box";
@import "composants/search";
@import "composants/pagination";
@import "composants/popin";


// Accessibility
@import "accessibility/highContrast";

//
//// QUERIES
//$bp0: 1240px;
//$bp1: 1100px;
//$bp2: 900px;
//$bp3: 640px;
//$bp4: 400px;
//
//@media screen and (max-width: $bp0) {
//  @import "queries/_xlarge";
//}
//
//@media screen and (max-width: $bp1) {
//  @import "queries/_large";
//}
//
//@media screen and (max-width: $bp2) {
//  @import "queries/_medium";
//}
//
//@media screen and (max-width: $bp3) {
//  @import "queries/_small";
//}
//
//@media screen and (max-width: $bp4) {
//  @import "queries/_xsmall";
//};
