// Directory auto-completion

.searchbar-input-wrapper {
  display: inline-block;
}

.searchbar-autocomplete-results {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    position: absolute;
    z-index: 990;
    display: block;
    overflow-y: auto;
    //width: 250px;
    width: auto;
    min-width: 300px;
    max-height: 50vh;
    padding: 0.375rem 0.75rem;
    color: $color-grey2;
    //border: 1px solid #ced4da;
    //border-radius: 0.25rem;
    background-color: $color-white;
    //background-clip: padding-box;
  text-align: left;
}
.searchbar-autocomplete-results .suggestion-choice {
    padding: 0.25em;
    cursor: pointer;
    //border-radius: 0.25rem;
}
.searchbar-autocomplete-results .suggestion-choice:hover {
    background: rgba(236,236,236,.5);
}

.searchbar-autocomplete-results li::marker {
  display: none;
}

section.search {
  h2:first-of-type {
    margin-top: 30px;
    font-size: 2.25rem;
    font-weight: 700;
    em {
      font-weight: 600;
    }
  }
}

.searchForm {
  position: relative;
  z-index: 99;
}

.searchResult {
  list-style: none;
  padding-left: 0;
  li {
    padding-bottom: 45px;
    margin-bottom: 45px;
    border-bottom: 1px solid $color-dark-blue;
    &:last-child {
      border-bottom: 0;
    }
  }
  &__title {
    margin-bottom: 1.25rem;
    line-height: normal;
    a {
      color: $color-dark-blue;
      font-family: $primary-font;
      font-size: 1.375rem;
      font-weight: 700;
      font-style: normal;
      text-decoration: none;
    }
    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__paragraph {
    color: $color-dark-blue;
    font-family: $primary-font;
    font-size: 1.375rem;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 1.25rem;
  }
  .highlighted {
      font-weight: 700;
  }

  &__link {
    font-weight: 600;
    line-height: 36px;
    &:before {
      content: "·";
      width: 2px;
      height: 2px;
      display: inline-block;
      margin-right: 34px;
    }
  }
}

@media (min-width: $large) {
  .searchForm {
    position: absolute;
    right: 0;
  }
}