.footer {
  padding: 0;
  padding-top: 34px;
  margin-top: 50px;
  background-color: $color-dark-blue;

  &__logo {
    width: 173px;
    height: 103px;
  }

  p, a, li {
    font-family: $primary-font;
    color: $color-white;
    font-size: 1rem;
    font-weight: 600;
  }

  a {
    text-decoration: none;
    padding: 12px;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  &__column {
    display: flex;
    &:nth-child(2) {
      justify-content: center;
      p {
        margin-bottom: 24px;
        strong, strong a{
          font-weight: 700;
        }
      }
    }
    &:last-child {
      justify-content: flex-end;
      text-align: right;
      li {
        margin-bottom: 3px;
        padding: 3px;
      }
    }
  }

  &__copyright {
    //display: flex;
    //justify-content: center;
    margin-top: 80px;
    p, a {
      font-size: .875rem;
      font-style: italic;
      line-height: 1.2rem;
    }
  }
  &__social {
    &List {
      //float: right;
      margin: 0;
    }
    &Item {
      display: inline-block;
      margin-right: 13px;
      &:last-child {
        margin-right: 0;
      }

    }
  }
}

@media (max-width: $medium) {
  .footer {
    padding-top: 12px;
    &__column {
      justify-self: center;
      margin-bottom: 3rem;
      p, img, ul {
        margin-bottom: 0;
      }
      &:last-child {
        margin-bottom: 0;
        text-align: left;
      }
    }
    &__copyright {
      margin-top: 0;
    }
    &__social {
      grid-row: 1;
      margin-top: 1rem;
      margin-bottom: 3rem;
    }
  }
}