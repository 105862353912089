.header {
  position: relative;
  background-image: url(/static/core/img/bg.jpg);
  background-color: #838383;
  background-repeat: no-repeat;
  background-size: cover;
  height: 420px;
  &__logo {
    width: 173px;
    height: 103px;
    margin-bottom: 0;
  }
  &__search {
    position: absolute;
    right: 1rem;
    text-align: right;
    a {
      color: $color-red;
      font-family: $primary-font;
      font-size: 1rem;
      font-weight: 700;
      text-decoration: none;
    }
  }
  &__title {
    position: absolute;
    bottom: 40px;
    span {
      background-color: $color-red;
      color: $color-white;
      font-family: $font2;
      font-size: 1.81rem;
      font-style: italic;
      font-weight: 400;
      letter-spacing: normal;
      line-height: 1.5;
      padding: 3px 7px;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
      &:first-letter {
        text-transform: capitalize;
      }
    }
    h1 {
      margin-bottom: 0;
      & > span {
        display: initial;
        width: 100%;
        min-width: 0;
        max-width: 450px;
      }
    }
    p {
      margin-bottom: 20px;
    }
  }
}

.home .header {
  height: 554px;
  &__title {
    position: relative;
    bottom: initial;
  }
}

@media (max-width: $medium) {
  .home .header__title {
    display: flex;
    height: 400px;
    & > p {
      align-self: center;
    }
  }
}

@media (min-width: $large) {
  .header {
    height: 690px;
    &__topBar {
      margin-top: 1rem;
    }
    &__search {
      position: relative;
      right: initial;
      margin-bottom: 4rem;
      padding-top: 2rem;
      text-align: right;
      .search__button {
        padding-right: 60px;
      }
    }
    &__title {
      position: absolute;
      bottom: 40px;

      span {
        padding-right: 2rem;
      }

      h1 {
        margin-bottom: 0;

        & > span {
          display: inline-block;
        }
      }
    }
  }
  .home .header {
    height: 100vh;
    &__title {
      top: 0;
      transform: translate(0, 50%);
    }
  }
}