/* 
 * Global utility classes 
 */

@each $class, $prop, $value in $utils {
  .#{$class} {
    #{$prop}: #{$value};
  }
}

// loop for each breakpoint
@each $class, $prop, $value in $utils {
  @each $bp, $bpv in $breakpoints {
    @media (min-width: #{$bpv}) {
      .#{$bp}\:#{$class} {
        #{$prop}: #{$value};
      }
    }
  }
}

// multi-properties utils
.is-unstyled {
  list-style: none;
  padding-left: 0;
}
.is-disabled,
[disabled] {
  opacity: 0.6;
  cursor: not-allowed !important;
  filter: grayscale(1);
}
.text-normal {
  font-weight: normal;
  font-style: normal;
  text-transform: none;
}

// loop for each breakpoint
@each $bp, $bpv in $breakpoints {
  @media (min-width: #{$bpv}) {
    .#{$bp}\:visually-hidden {
      position: absolute !important;
      border: 0 !important;
      height: 1px !important;
      width: 1px !important;
      padding: 0 !important;
      overflow: hidden !important;
      clip: rect(0, 0, 0, 0) !important;
    }
    .#{$bp}\:is-unstyled {
      list-style: none;
      padding-left: 0;
    }
    .#{$bp}\:is-disabled {
      opacity: 0.6;
      cursor: not-allowed !important;
      filter: grayscale(1);
    }
    .#{$bp}\:text-normal {
      font-weight: normal;
      font-style: normal;
      text-transform: none;
    }
  }
}
