// Main navigation #####################
#menu_item_1.mainNav__subMenu:target,
#menu_item_2.mainNav__subMenu:target,
#menu_item_3.mainNav__subMenu:target {
  display: flex;
}
.mainNav {
  display: none;
  width: 75%;

  &__list {
    display: flex;
    position: relative;
    justify-content: space-between;
  }

  &__item {
    display: inline-flex;
    max-width: 217px;
    &:hover > .mainNav__subMenu {
      display: flex;
    }
  }

  &__link {
    display: inline-block;
    align-self: flex-end;
    text-decoration: none;
    color: $color-white;
    font-family: $primary-font;
    font-size: 1.875rem;
    font-weight: 700;
    line-height: 2.25rem;
    position: relative;
    padding-bottom: 15px;
    text-align: left;
    &:hover {
      color: $color-white;
      text-decoration: none;
    }

  }
  .mainNav__item:hover .mainNav__link:after,
  .mainNav__item.selected .mainNav__link:after {
      content: "";
      width: 100%;
      background-color: $color-white;
      height: 7px;
      position: absolute;
      bottom: 0px;
      left: 0;
    }

  &__subMenu {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
  }
  .subMenu {
    width: calc($large - 2rem);
    padding: 50px 1.25rem 150px;
    background: $color-white;
    margin-top: 15px;
    z-index: 1;
    &:before {
      content: "";
      height: 15px;
      position: absolute;
      width: 100%;
      top: -15px;
      left: 0;
    }
    &__item {
      flex: 1;
      width: 100%;
      margin: 0 15px;
      &:first-letter {
        text-transform: uppercase;
      }
    }
    &__link {
      padding: 0;
      margin: 0 0 15px;
      color: $color-dark-blue;
      font-family: $primary-font;
      font-size: 1.375rem;
      font-weight: 700;
      text-decoration: none;
    }
    &__subList {
      margin-top: 55px;
    }
    &__subItem {
      font-weight: normal;
      margin: 0;
      padding: 0;
      &:first-letter {
        text-transform: uppercase;
      }
    }
    &__subLink {
      color: $color-dark-blue;
      font-family: $primary-font;
      font-size: 1.375rem;
      font-weight: 400;
      line-height: 1.875rem;
      text-decoration: none;

    }
  }

}

.mainNav.mainNav--is-opened {
  position: absolute;
  top: 154px;
  left: 0;
  min-height: 100vh;
  width: 100%;
  display: block;
  background-color: $color-white;
  z-index: 999;
  padding: 25px;
  .mainNav {
    &__list, &__item, &__subMenu {
      position: relative;
      display: block;
      width: 100%;
      max-width: none;
      padding: 0;
      margin: 0;
    }
    &__item {
      margin-top: 40px;
      &:first-child {
        margin-top: 0;
      }
    }
    &__link {
      margin: 0;
      padding: 0;
      color: $color-dark-blue;
    }
    .subMenu {
      margin: 0;
      width: 100%;
      &:before {
        content: none;
      }
    }
  }
  .subMenu {
    margin-top: 34px;
    &__subList {
      margin: 38px 0;
    }
    &__item {
      flex: none;
      margin: 0;
      padding: 0;
    }
    &__link {
      font-family: $font2;
      font-size: 1.5rem;
      font-weight: 600;
      font-style: italic;
    }
    &__subLink {
      font-family: $font2;
      font-size: 1.31rem;
      font-weight: 500;
      font-style: italic;
    }
    &__subItem {
      padding-left: 27px;
      padding-top: 10px;
      padding-bottom: 10px;
      &:first-child {
        padding-top: 0;
      }
    }
  }
}

.mainNav .topNavMobile {
  display: none;
  visibility: hidden;
}

.mainNav.mainNav--is-opened .topNavMobile {
  display: block;
  visibility: visible;
  margin-top: 40px;
  margin-bottom: 120px;
  padding-top: 42px;
  border-top: 3px solid $color-dark-blue;
  &__item {
    margin-top: 40px;
    &:first-child {
      margin-top: 0;
    }
  }
  &__link {
      font-family: $primary-font;
      font-size: 1.5rem;
      font-weight: 600;
      font-style: normal;
      text-decoration: none;
  }
}


@media (min-width: $medium) {
  .mainNav {
    display: block;
    float: right;
    border-top: 0;
    margin-top: 35px;
  }
  .subMenu {
    width: calc($medium - 2rem);
  }
}

@media (min-width: $large) {
  .subMenu {
    width: calc($large - 2rem);
  }
}