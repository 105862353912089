.slider {
  overflow: hidden;
  width: 100%;
  max-width: 900px;
  &__item {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 900px;
    height: 515px;
  }
  .captions {
    margin-top: -60px;
  }
  &__caption p {
    bottom: 0;
    width: 80%;
    max-width: 715px;
    height: 60px;
    line-height: 60px;
    padding-right: 37px;
    margin-bottom: 0;
    background-color: $color-red;
    color: #ffffff;
    font-family: $primary-font;
    font-size: 1.125rem;
    font-weight: 600;
    font-style: normal;
    text-align: right;
    overflow: hidden;
    float: right;
    position: relative;
    display: block;
  }
}

.slick-prev, .slick-next {
  z-index: 99!important;
}
.slick-prev {
  left: 10px!important;
}
.slick-next {
  right: 10px !important;
}