// Top nav

.topNav {
  display: flex;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 15px 23px;
  &__list {
    padding: 0;
    margin: 0;
    line-height: 55px;
    position: relative;
  }
  &__item {
    display: inline-block;
    padding: 0;
    padding-right: 25px;
    &:first-child {
      margin-left: 0;
      padding-left: 0;
      &:after {
        position: relative;
        top: 13px;
        display: inline-block;
        content: "";
        height: 40px;
        padding: 0;
        margin: 0 0 0 25px;
        border-right: 2px solid #002845;
      }
    }
    &:last-child {
      margin-right: 0;
      padding-right: 0;
      &:after {
        display: none;
      }
    }
  }

  &__link {
    color: $color-dark-blue;
    font-family: $primary-font;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
  }
  .button--blue {
    color: $color-white;
  }
}
