
.thumbnail {
    &__item {
      display: inline-block;
      width: 100%;
      max-width: 380px;
      height: auto;
      background-color: $color-white;
      justify-self: center;
    }
    &__image {
      position: relative;
      width: 100%;
      height: 225px;
      display: block;
      overflow: hidden;
    }
    &__date {
      background-color: $color-red;
      color: $color-white;
      display: inline-block;
      font-family: $primary-font;
      font-size: 1.125rem;
      font-weight: 600;
      width: 50%;
      text-align: right;
      padding-right: 20px;
      padding-top: 13px;
      padding-bottom: 13px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
    &__caption {
      background-color: $color-white;
      padding: 13px 15px 13px 0;
    }
    &__categories {
      &Item {
        font-family: $primary-font;
        font-size: 1.125rem;
        font-weight: 400;
        text-transform: uppercase;
        text-decoration: none;
      }
    }

    &__title, &__title a {
      font-family: $primary-font;
      font-size: 1.375rem;
      font-weight: 700;
      text-decoration: none;
      line-height: normal;
      margin-bottom: 30px;
    }

    &Media {
      margin-top: 50px;
      &__item {
        display: flex;
        height: auto;
        margin-bottom: 1rem;
        align-items: end;
      }
      &__image {
        height: auto;
        img {
          margin-bottom: 0;
        }
      }
    }
}

.home .thumbnail {
  &__item {
    margin-bottom: 40px;
  }
  &__caption {
    padding: 0;
    padding-top: 15px;
    h3 {
      margin-bottom: 0;
    }
  }
}

@media (min-width: $large) {
  .thumbnail__categories {
    margin-bottom: 22px;
  }
}