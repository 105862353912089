/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * mic-32-new-rounded-web:
 *   - http://typekit.com/eulas/000000000000000077359c5f
 *   - http://typekit.com/eulas/000000000000000077359c62
 *   - http://typekit.com/eulas/000000000000000077359c8b
 *   - http://typekit.com/eulas/000000000000000077359c83
 * mic-32-new-web:
 *   - http://typekit.com/eulas/000000000000000077359c65
 *   - http://typekit.com/eulas/000000000000000077359c68
 *   - http://typekit.com/eulas/000000000000000077359c86
 *   - http://typekit.com/eulas/000000000000000077359c8d
 *
 * © 2009-2022 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2022-09-26 15:46:48 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=skm4jft&ht=tk&f=8448.8449.8454.8455.14429.14430.14435.14436&a=4262462&app=typekit&e=css");

@font-face {
  font-family:"mic-32-new-web";
  src:url("https://use.typekit.net/af/47ed3b/000000000000000077359c65/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/47ed3b/000000000000000077359c65/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/47ed3b/000000000000000077359c65/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-style:normal;font-weight:700;font-stretch:normal;
  font-display: swap;
}

@font-face {
  font-family:"mic-32-new-web";
  src:url("https://use.typekit.net/af/62d275/000000000000000077359c68/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/62d275/000000000000000077359c68/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/62d275/000000000000000077359c68/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-style:italic;font-weight:700;font-stretch:normal;
  font-display: swap;
}

@font-face {
  font-family:"mic-32-new-web";
  src:url("https://use.typekit.net/af/5a2eb3/000000000000000077359c86/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/5a2eb3/000000000000000077359c86/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/5a2eb3/000000000000000077359c86/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-style:normal;font-weight:400;font-stretch:normal;
  font-display: swap;
}

@font-face {
  font-family:"mic-32-new-web";
  src:url("https://use.typekit.net/af/e84ba2/000000000000000077359c8d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/e84ba2/000000000000000077359c8d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/e84ba2/000000000000000077359c8d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-style:italic;font-weight:400;font-stretch:normal;
  font-display: swap;
}

@font-face {
  font-family:"mic-32-new-rounded-web";
  src:url("https://use.typekit.net/af/cc2574/000000000000000077359c5f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/cc2574/000000000000000077359c5f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/cc2574/000000000000000077359c5f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-style:normal;font-weight:700;font-stretch:normal;
  font-display: swap;
}

@font-face {
  font-family:"mic-32-new-rounded-web";
  src:url("https://use.typekit.net/af/edb62d/000000000000000077359c62/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/edb62d/000000000000000077359c62/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/edb62d/000000000000000077359c62/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-style:italic;font-weight:700;font-stretch:normal;
  font-display: swap;
}

@font-face {
  font-family:"mic-32-new-rounded-web";
  src:url("https://use.typekit.net/af/3f9b3b/000000000000000077359c8b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/3f9b3b/000000000000000077359c8b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/3f9b3b/000000000000000077359c8b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-style:normal;font-weight:400;font-stretch:normal;
  font-display: swap;
}

@font-face {
  font-family:"mic-32-new-rounded-web";
  src:url("https://use.typekit.net/af/51ee63/000000000000000077359c83/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/51ee63/000000000000000077359c83/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/51ee63/000000000000000077359c83/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-style:italic;font-weight:400;font-stretch:normal;
  font-display: swap;
}

.tk-mic-32-new-web { font-family: "mic-32-new-web",sans-serif; }
.tk-mic-32-new-rounded-web { font-family: "mic-32-new-rounded-web",sans-serif; }

// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

// Font Variables
$primary-font: 'Source Sans Pro', sans-serif;
$font2: "mic-32-new-web", sans-serif;
$font2-rounded: "mic-32-new-rounded-web", sans-serif;
