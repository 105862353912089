.annuaire {
  margin-bottom: 0;
  position: relative;
  padding: 0;

  &__column:first-child {
    position: absolute;
    display: inline-block;
    bottom: 94px;
    padding-right: 4rem;
    width: 60%;
    float: right;
  }

  &__column:nth-child(2) {
    width: 40%;
    float: right;
    padding-left: 55px;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: $color-dark-blue;
    position: relative;
    max-height: 620px;
  }

  &__text p {
    color: $color-dark-blue;
    font-family: $font2;
    font-size: 1.875rem;
    font-weight: 400;
    font-style: italic;
    line-height: 44px;
    text-align: right;
    margin-bottom: 0;
  }
  &__formTitle {
    font-family: $primary-font;
    color: $color-white;
    font-size: 2.25rem;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 38px;
  }

  &__list {
    margin-top: 38px;
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 0;
    ul {
      margin-bottom: 0;
      padding-left: 0;
    }

    &Item, li {
      width: 100%;
      max-width: 339px;
      font-family: $primary-font;
      color: $color-white;
      font-size: 1.125rem;
      font-weight: 600;
      font-style: italic;
      list-style: none;
      padding: 34px 0;
      border-top: 1px solid $color-white;

      strong {
        font-weight: 700;
        color: $color-white;
        font-size: 1.125rem;
      }

      a {
        font-weight: 600;
        color: $color-white;
        font-size: 1.125rem;
        text-decoration: none;
      }

      &:last-child {
        border-bottom: 1px solid $color-white;
      }
    }
  }


  &Result {
    padding-top: 50px;
    [aria-controls] {
      cursor: default !important;
    }
    &__searchBox {
      padding: 1.4rem .7rem;
      background-color: $color-dark-blue;
      .searchbar-input-wrapper {
        //width: 80%;
        input[type=search] {
          max-width: 100%;
          width: 320px;
          margin-right: 0px;
        }
      }
      .button {
        margin-left: -4px;
      }
    }
    &__searchBoxTitle {
      margin: 0 0 .625rem;
      color: $color-white;
      line-height: normal;
    }
    &__aside {
      padding-right: 34px;
      p {
        font-family: $primary-font;
        color: $color-dark-blue;
        font-size: 1.875rem;
        line-height: normal;
        font-weight: 700;
      }
    }
    .ui-tabs-tab {
      list-style: none;
    }
    &__tab {
      padding: 20px 0;
      margin-bottom: 15px;
      text-align: center;
      font-size: 1rem;
      font-weight: 700;
      font-family: $font2;
      color: $color-dark-blue;
      background-color: #e6e6e6;
      display: block;
      text-decoration: none;
      position: relative;
      line-height: 40px;
      &:after {
        display: none;
        position: absolute;
        content: "";
        background-image: url(/static/core/img/arrow-right-black.png);
        background-position: 50%;
        background-repeat: no-repeat;
        height: 40px;
        padding: 0;
        width: 40px;
        right: 0;
      }
      &--active {
        background-image: url(/static/core/img/arrow-right.png);
        color: $color-white;
        background-color: $color-red;
        margin-bottom: 0;
        &:hover {
           color: $color-white;
        }

      }
      &:hover {
        color: $color-white;
        background-color: $color-red;
        text-decoration: none;
        cursor: pointer;
      }
    }
    &__row {
      border-bottom: 1px solid $color-grey;
      padding-top: 30px;
      padding-bottom: 17px;
      &:last-of-type {
        margin-bottom: 30px;
      }

      h3, h4, p, a {
        margin: 0;
        padding: 0;
        font-family: $primary-font;
        color: $color-dark-blue;
        font-size: 1.375rem;
        line-height: 2.25rem;
      }

      h3, h3 a {
        font-weight: 700;
      }

      h4 {
        font-weight: 600;
      }

      p {
        font-weight: 600;
      }
      a {
        text-decoration: none;
      }
    }
  }
}

.ui-tabs-nav {
  padding-left: 0;
}

.ui-tabs-active > a {
  color: $color-white;
  background-color: $color-red;
  text-decoration: none;
  &:after {
    transform: rotate(90deg);
    background-image: url(/static/core/img/arrow-right.png);
    background-repeat: no-repeat;
    background-position: 50%;
  }
}

.ui-tabs-tab > a:hover:after {
  transform: rotate(90deg);
  background-image: url(/static/core/img/arrow-right.png);
  background-repeat: no-repeat;
  background-position: 50%;
}

@media (max-width: $medium) {
  .annuaire {
    &__column:first-child {
      display: none;
    }
    &__column:nth-child(2) {
      width: 100%;
      display: block;
    }
    &Result {
      &__searchBox {
        .searchbar-input-wrapper {
          width: calc(100% - 40px);
          input[type=search] {
            display: inline-block;
            max-width: 320px;
            //width: 100%;
          }
        }
      }
      &Result__aside {
        padding-left: 1rem;
      }
    }
  }
}

@media (min-width: $large) {
  .annuaire {
    padding: 1rem 0;
    margin-bottom: 84px;

  }
}
